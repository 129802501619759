import * as React from 'react';
import { View } from 'react-native';

import { Header } from '@/components/Header';
import { SafeAreaView } from '@/components/SafeAreaView';

import { Tabs } from './components/Tabs';
import { styles } from './styles';

export function Sync() {
  return (
    <SafeAreaView style={{ minHeight: '100%' }}>
      <Header name="Sincronizações" />
      <View style={styles.container}>
        <Tabs />
      </View>
    </SafeAreaView>
  );
}
