import { StyleSheet } from 'react-native';

import { Border, Color, FontFamily, FontSize } from '@/styles/GlobalStyles';

export const optionChecked = {
  borderRadius: Border.xs,
  border: `1px solid ${Color.primary[300]}`,
  backgroundColor: Color.primary.opacity_12_300,
};

export const styles = StyleSheet.create({
  optionUnchecked: {
    borderRadius: Border.xs,
    border: `1px solid ${Color.gray[100]}`,
    backgroundColor: Color.gray.white,
  },
  labelOption: {
    flexGrow: 0,
    fontFamily: FontFamily.regular, // 400
    fontSize: FontSize.base,
  },
});
