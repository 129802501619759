import { useClerk } from '@clerk/clerk-react';
import { useNavigation } from '@react-navigation/native';
import React, { useContext, useState } from 'react';
import { FlatList, Modal, Pressable, Text, View } from 'react-native';
import { useToast } from 'react-native-toast-notifications';
import * as Sentry from 'sentry-expo';

import { useAudioContext } from '@/contexts/AudioContext';
import { InterviewContext } from '@/contexts/InterviewContext';
import { useSyncContext } from '@/contexts/syncContext';
import type { IFormData, ISteps } from '@/interfaces/formInterfaces';
import { FormService } from '@/services/FormService';

import { version as APP_VERSION } from '../../../package.json';
import { AudioComponent } from '../Audio';
import { Inputs } from './components/Inputs';
import { styles } from './styles';

interface IProps {
  dataToRender: ISteps[];
  sessionType: string;
}

export function InterviewForm({ dataToRender, sessionType }: IProps) {
  const navigation = useNavigation();
  const [isLoading, setIsLoading] = useState(false);
  const [modalVisible, setModalVisible] = useState(false);
  const [modalText, setModalText] = useState('');
  const [buttonText, setButtonText] = useState('Próxima');
  const {
    stepIndex,
    questionIndex,
    formData,
    answeredQuestions,
    canAnswer,
    sampleToUpdate,
    resetAnswers,
    questionsToRender,
    setNextQuestion,
    getPrevQuestion,
  } = useContext(InterviewContext);
  const { stopAudioRecording } = useAudioContext();
  const toast = useToast();
  const { setSync, isSyncing, cancelIsSyncing } = useSyncContext();
  const { session } = useClerk();

  Sentry.Browser.setContext('interview_form', {
    stepIndex,
    questionIndex,
    canAnswer,
    isSyncing,
    clerkUserId: session?.user.id,
    clerkSessionId: session?.id,
  });

  const isAnsweredQuestion = () => {
    const currentQuestion = questionsToRender[0].questions[0].id;
    const isAnswered = !!answeredQuestions.find(
      (answer) =>
        answer.question_id === currentQuestion &&
        ((!answer.type.includes('Texto') &&
          !answer.type.includes('Número') &&
          answer.values?.length > 0) ||
          !!answer.text),
    );
    Sentry.Browser.addBreadcrumb({
      category: 'question',
      message: `Checking if question is answered: ${isAnswered}`,
      level: 'info',
    });

    return isAnswered;
  };

  const isDisabled = () => {
    const lastStepIndex = formData!.steps.length - 1;
    const isLastQuestion = !(
      stepIndex === lastStepIndex &&
      questionIndex === formData!.steps[lastStepIndex].questions.length - 1
    );
    Sentry.Browser.addBreadcrumb({
      category: 'navigation',
      message: `Checking if navigation is disabled: ${!isLastQuestion}`,
      level: 'info',
    });
    return isLastQuestion;
  };

  const onClick = async () => {
    if (isDisabled()) {
      setButtonText('Próxima');
    } else {
      setButtonText('Enviar entrevista');
    }
    const currentQuestion = questionsToRender[0].questions[0].id;
    const isAnswered = answeredQuestions.find(
      (answer) => answer.question_id === currentQuestion,
    );
    if (formData !== undefined && isAnswered)
      if (isAnswered && isAnswered.skipTo !== null) {
        return setNextQuestion(isAnswered.skipTo);
      }
    return setNextQuestion();
  };

  const save = async () => {
    setIsLoading(true);
    const transaction = Sentry.Browser.startTransaction({
      name: 'Save Interview Form',
    });
    Sentry.Browser.configureScope((scope) => scope.setSpan(transaction));

    const id = !isSyncing
      ? toast.show('Enviando...', {
          type: 'info',
          placement: 'top',
          duration: 20000,
        })
      : null;
    if (stepIndex > 0 && !sampleToUpdate?.sampleId) {
      Sentry.Browser.captureException('Salvamento de entrevista sem amostra.', {
        extra: {
          formData,
          sampleToUpdate,
          clerkId: session?.user.id,
          username: session?.user.username,
          questionsToRender: {
            ...questionsToRender[0],
            questionId: questionsToRender[0]?.questions[0]?.id,
          },
          appVersion: APP_VERSION,
        },
      });
    }
    const updatedSample = sampleToUpdate.sampleId
      ? FormService.saveFormAnswers(
          formData as IFormData,
          answeredQuestions,
          sampleToUpdate && sampleToUpdate.sampleId.toString(),
        ).then((interviewId) => {
          stopAudioRecording(interviewId);
        })
      : await FormService.saveFormAnswers(
          formData as IFormData,
          answeredQuestions,
        ).then((interviewId) => {
          stopAudioRecording(interviewId);
        });
    setSync()
      .then(() => {
        if (id) {
          toast.update(id, 'Sincronia concluída', {
            type: 'success',
            duration: 4000,
          });
          setModalText('Banco de dados sincronizado com sucesso.');
        }
      })
      .catch((error) => {
        if (id) {
          toast.update(id, 'Erro na sincroniza, procure o administrador', {
            type: 'danger',
            duration: 4000,
          });
          setModalText(`Não foi possível sincronizar, tente novamente mais tarde.
            Erro: ${error.message}`);
          cancelIsSyncing();
        }
      });
    navigation.navigate('home');
    resetAnswers();
    transaction.finish();
    return updatedSample;
  };

  const getPrevData = () => {
    getPrevQuestion();
  };

  return (
    <View style={styles.formContainer}>
      {sessionType === 'presencial' && <AudioComponent />}

      <FlatList
        data={dataToRender}
        contentContainerStyle={{ gap: 40 }}
        style={{
          paddingBottom: 40,
          paddingHorizontal: '9%',
          flex: 1,
        }}
        renderItem={({ item }) => (
          <View style={{ gap: 20 }}>
            <Inputs questions={item.questions} stepOrder={item.order} />
          </View>
        )}
      />

      <View style={styles.fixedButton}>
        <Pressable
          onPress={getPrevData}
          style={[styles.buttons, styles.prevButton]}
        >
          <Text style={styles.prevButtonText}>Anterior</Text>
        </Pressable>
        {!canAnswer ? (
          <View style={[styles.quotaAlert]}>
            <Text style={[styles.saveButtonText]}>
              Esta cota já foi preenchida, não é possível continuar a entrevista
            </Text>
          </View>
        ) : (
          <Pressable
            onPress={isDisabled() ? onClick : save}
            disabled={!isAnsweredQuestion() || isLoading}
            style={[
              styles.buttons,
              styles.nextButton,
              !isAnsweredQuestion() || isLoading
                ? [styles.disabledSaveButton]
                : [styles.saveButton],
            ]}
          >
            <Text
              style={[
                styles.saveButtonTextDefault,
                !isAnsweredQuestion() || isLoading
                  ? styles.saveButtonDisabledText
                  : styles.saveButtonText,
              ]}
            >
              {buttonText}
            </Text>
          </Pressable>
        )}
      </View>

      <Modal
        animationType="fade"
        transparent
        visible={modalVisible}
        onRequestClose={() => {
          navigation.navigate('home');
        }}
      >
        <View style={styles.centeredView}>
          <View style={styles.modalView}>
            <Text style={styles.modalText}>{modalText}</Text>
            <Pressable
              style={[styles.button, styles.buttonClose]}
              onPress={() => {
                setModalVisible(!modalVisible);
                navigation.navigate('home');
                resetAnswers();
              }}
            >
              <Text style={styles.textStyle}>Voltar</Text>
            </Pressable>
          </View>
        </View>
      </Modal>
    </View>
  );
}
