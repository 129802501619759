import { StyleSheet } from 'react-native';

import {
  Border,
  Color,
  FontFamily,
  FontSize,
  Padding,
} from '@/styles/GlobalStyles';

export const styles = StyleSheet.create({
  container: {
    backgroundColor: Color.gray[50],
    height: 1366,
    paddingHorizontal: '9%',
    paddingVertical: 160,
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'row',
    width: '100%',
    flex: 1,
  },
  loginContainer: {
    marginLeft: 10,
    alignItems: 'center',
    flex: 1,
  },
  logo: {
    maxWidth: '100%',
    maxHeight: '100%',
    width: 170,
    height: 48,
  },
  formContainer: {
    marginTop: 42,
    alignSelf: 'stretch',
    alignItems: 'center',
  },
  inputsContainer: {
    alignSelf: 'stretch',
    flexDirection: 'column',
    gap: 30,
  },
  inputContainer: {
    zIndex: 0,
    alignSelf: 'stretch',
    flexDirection: 'column',
    gap: 10,
  },
  label: {
    textAlign: 'left',
    fontFamily: FontFamily.medium,
    letterSpacing: 0,
    fontSize: FontSize.base,
    color: Color.gray[400],
  },
  inputContainerWithIcon: {
    flexDirection: 'row',
    width: '100%',
    justifyContent: 'space-between',
    alignItems: 'center',
    borderWidth: 1,
    borderColor: Color.gray[100],
    borderStyle: 'solid',
    backgroundColor: Color.gray.white,
    borderRadius: Border.lg,
    paddingHorizontal: Padding.p_base,
  },
  input: {
    height: 49,
    flex: 1,
    fontFamily: FontFamily.regular,
    fontSize: FontSize.base,
    paddingVertical: Padding.p_sm,
    outlineStyle: 'none',
  },
  icon: {
    marginLeft: 12,
    color: Color.gray[200],
    width: 28,
    height: 28,
  },
  inputWithBorder: {
    borderWidth: 1,
    borderColor: Color.gray[100],
    borderStyle: 'solid',
    backgroundColor: Color.gray.white,
    borderRadius: Border.lg,
    paddingHorizontal: Padding.p_base,
  },
  buttonsContainer: {
    marginTop: 40,
    alignSelf: 'stretch',
    alignItems: 'center',
  },
  loginButton: {
    backgroundColor: Color.primary[300],
    shadowColor: 'rgba(39, 118, 167, 0.1)',
    shadowOffset: {
      width: 0,
      height: 4,
    },
    shadowRadius: 6,
    elevation: 6,
    shadowOpacity: 1,
    paddingHorizontal: Padding.p_5xl,
    paddingVertical: Padding.p_xs,
    borderRadius: Border.lg,
    alignSelf: 'stretch',
    overflow: 'hidden',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'row',
  },
  loginButtonDisabled: {
    opacity: 0.8,
  },
  loginButtonText: {
    lineHeight: 24,
    color: Color.gray.white,
    textAlign: 'center',
    fontFamily: FontFamily.medium,
    fontSize: FontSize.base,
  },
  errorMessage: {
    fontFamily: FontFamily.medium,
    fontSize: FontSize.base,
    color: Color.secondary.red,
    marginTop: -10,
  },
});
