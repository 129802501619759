import { StyleSheet } from 'react-native';

import { Color, FontFamily, FontSize, Padding } from '@/styles/GlobalStyles';

export const styles = StyleSheet.create({
  container: {
    flexDirection: 'column',
    gap: 1,
    width: '100%',
    flex: 1,
    alignItems: 'flex-start',
  },
  rows: {
    flexDirection: 'row',
    flex: 1,
    width: '100%',
  },
  headerCells: {
    backgroundColor: Color.primary[300],
    paddingVertical: 13,
    paddingHorizontal: Padding.p_5xs,
    height: 40,
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    flex: 1,
    boxSizing: 'border-box',
    borderBottomWidth: 1,
    borderRightWidth: 1,
    borderColor: Color.gray[100],
  },
  headerCellId: {
    width: 70,
    borderTopLeftRadius: 8,
  },
  headerCellStatus: {
    borderTopRightRadius: 8,
    borderRightWidth: 0,
  },
  text: {
    color: Color.gray.white,
    fontFamily: FontFamily.medium,
    textTransform: 'capitalize',
    lineHeight: 16,
    fontSize: FontSize.sm,
    textAlign: 'center',
    flex: 1,
  },
});
