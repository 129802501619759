import { StyleSheet } from 'react-native';

import { Color, Spacing } from '@/styles/GlobalStyles';

export const styles = StyleSheet.create({
  container: {
    flex: 1,
    alignSelf: 'stretch',
    height: '100%',
  },
  formContainer: {
    backgroundColor: Color.gray[50],
    paddingTop: Spacing.xl,
    flex: 1,
  },
});
