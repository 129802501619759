import { StyleSheet } from 'react-native';

import {
  Color,
  FontFamily,
  FontSize,
  Padding,
  Spacing,
} from '@/styles/GlobalStyles';

export const styles = StyleSheet.create({
  audioContainer: {
    paddingHorizontal: '9%',
    alignSelf: 'stretch',
    gap: 10,
    marginBottom: 20,
  },
  container: {
    flexDirection: 'row',
    paddingHorizontal: 20,
    paddingVertical: Padding.p_sm,
    elevation: Spacing.xl,
    shadowRadius: Spacing.xl,
    shadowColor: Color.gray.opacity_08_black,
    backgroundColor: Color.primary[200],
    shadowOpacity: 1,
    shadowOffset: {
      width: 0,
      height: 4,
    },
    borderRadius: 40,
    gap: 10,
    justifyContent: 'space-between',
  },
  text: {
    flexGrow: 0,
    fontFamily: FontFamily.regular, // 400
    fontSize: FontSize.base,
    color: 'white',
  },
});
