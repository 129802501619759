import { format, formatDuration, intervalToDuration } from 'date-fns';
import ptBR from 'date-fns/locale/pt-BR';

type IDurationFormat =
  | 'years'
  | 'months'
  | 'days'
  | 'weeks'
  | 'hours'
  | 'minutes'
  | 'seconds';

export class DateFormatter {
  static duration({
    createdAt,
    updatedAt,
    options = ['minutes', 'seconds'],
  }: {
    createdAt: number;
    updatedAt: number;
    options?: IDurationFormat[];
  }) {
    const duration = intervalToDuration({
      start: new Date(createdAt),
      end: new Date(updatedAt),
    });
    const formatted = formatDuration(duration, {
      format: options,
      zero: true,
      delimiter: ':',
      locale: {
        formatDistance: (_token, count) => String(count).padStart(2, '0'),
      },
    });

    return formatted;
  }

  static fullDate(date: number) {
    return format(new Date(date), 'dd/MM/yyyy', {
      locale: ptBR,
    });
  }

  static fullDateWithHoursAndMinutes(date: number) {
    return format(new Date(date), 'dd/MM/yyyy - HH:mm', {
      locale: ptBR,
    });
  }

  static hoursAndMinutes(date: number) {
    return format(new Date(date), 'HH:mm', {
      locale: ptBR,
    });
  }
}
