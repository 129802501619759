/* eslint-disable no-param-reassign */
import { useNavigation, useRoute } from '@react-navigation/core';
import React, { useEffect, useState } from 'react';
import { Pressable, Text, View } from 'react-native';
// eslint-disable-next-line unused-imports/no-unused-imports
import * as Sentry from 'sentry-expo';

import { SafeAreaView } from '@/components/SafeAreaView';

import { styles } from './styles';

export function Error() {
  const navigation = useNavigation();
  const returnToHome = () => {
    navigation.navigate('home');
  };
  const route = useRoute()
  const [errorText, setErrorText] = useState('Recarregue a página')

  useEffect(() => {
    const { type } = route.params as { type: string };
    if (type === 'answer') {
      setErrorText('Houve um erro ao recuperar as respostas do questionário, informe os responsáveis e retorne para a página inicial')
    } else if (type === 'quota') {
      setErrorText('Houve um erro ao recuperar as cotas do questionário, informe os responsáveis e retorne para a página inicial')
    } else if (type === 'sample') {
      setErrorText('Houve um erro ao recuperar as amostras do questionário, informe os responsáveis e retorne para a página inicial')
    }
  }, [])

  return (
    <SafeAreaView>
      <View style={styles.container}>
        <View style={styles.textContainer}>
          <Text style={styles.greetingUser}>
            Não foi possível iniciar a entrevista.
          </Text>
          <Text style={styles.message}>
            {errorText}
          </Text>
          <Pressable
            onPress={returnToHome}
            style={[styles.listItem, styles.row]}
          >
            <Text
              style={[styles.listText, styles.firstListItem]}
              numberOfLines={3}
              ellipsizeMode="tail"
            >
              Retornar
            </Text>
          </Pressable>
        </View>
      </View>
    </SafeAreaView>
  );
}
