import React, { useContext, useState } from 'react';
import { FlatList } from 'react-native';

import { InterviewContext } from '@/contexts/InterviewContext';
import type { TypeAnswerModel } from '@/database/model/TypeAnswer';
import type { IRenderConditions } from '@/interfaces/formInterfaces';

import { Card } from './Card';

interface IProps {
  questions: {
    label: string;
    description: string;
    type: string;
    order: number;
    isDropdown: boolean;
    isRandomOrderAnswer: boolean;
    value: TypeAnswerModel[];
    renderConditions: IRenderConditions[][];
  }[];
  stepOrder: number;
}

export function Inputs({ questions, stepOrder }: IProps) {
  const { saveAnsweredQuestions, answeredQuestions, stepIndex } =
    useContext(InterviewContext);
  const [savedTexts, setSavedTexts] = useState<{ id: string; text: string }[]>(
    [],
  );

  const onChange = (value: TypeAnswerModel, text = '') => {
    // SAVING MULTIPLE ANSWERS TYPE
    const hasAnswer = answeredQuestions.find(
      (question) => question.question_id === value.question_id,
    );
    if (value.type === 'Escolha múltipla') {
      if (hasAnswer) {
        if (hasAnswer.values.includes(value.id)) {
          const newAnswers = hasAnswer.values.filter(
            (idValue: string) => idValue !== value.id,
          );
          // substituir
          hasAnswer.values = newAnswers;
          return saveAnsweredQuestions(hasAnswer, value.question_id);
        }

        hasAnswer.values = [...hasAnswer.values, value.id];
        return saveAnsweredQuestions(hasAnswer, value.question_id);
      }
      return saveAnsweredQuestions(
        {
          question_id: value.question_id,
          type: value.type,
          skipTo: value.skip_to_question,
          is_quota: stepIndex === 0,
          values: [value.id],
        },
        value.question_id,
      );
    }
    if (value.type === 'Escolha única') {
      if (hasAnswer) {
        const newAnswer = {
          ...hasAnswer,
          values: [value.id],
          skipTo: value.skip_to_question,
        };
        return saveAnsweredQuestions(newAnswer, value.question_id);
      }
      return saveAnsweredQuestions(
        {
          question_id: value.question_id,
          type: value.type,
          is_quota: stepIndex === 0,
          values: [value.id],
          skipTo: value.skip_to_question,
        },
        value.question_id,
      );
    }
    if (hasAnswer) {
      const filterdeTexts = savedTexts.filter(
        (savedText) => savedText.id !== value.id,
      );
      setSavedTexts([...filterdeTexts, { id: value.id, text }]);

      const newAnswer = {
        ...hasAnswer,
        values: [value.id],
        text,
        is_quota: stepIndex === 0,
      };
      return saveAnsweredQuestions(newAnswer, value.question_id);
    }
    setSavedTexts([...savedTexts, { id: value.id, text }]);
    return saveAnsweredQuestions(
      {
        question_id: value.question_id,
        type: value.type,
        text,
        is_quota: stepIndex === 0,
        values: [value.id],
        skipTo: value.skip_to_question,
      },
      value.question_id,
    );
  };

  const findText = (id: string) => {
    const savedValue = savedTexts.find((text) => text.id === id);
    return savedValue ? savedValue.text : '';
  };

  return (
    <FlatList
      data={questions}
      style={{ gap: 20 }}
      renderItem={({ item }) => (
        <Card
          label={item.label}
          type={item.type}
          value={item.value}
          onChange={onChange}
          findText={findText}
          isDropdown={item.isDropdown}
          isRandomOrderAnswer={item.isRandomOrderAnswer}
          stepOrder={stepOrder}
        />
      )}
    />
  );
}
