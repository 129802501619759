import { Model } from '@nozbe/watermelondb';
import { field } from '@nozbe/watermelondb/decorators';

export class SampleModel extends Model {
  static table = 'samples';

  @field('sample_id')
  sample_id!: number;

  @field('quotas_id')
  quotas_id!: number;

  @field('value')
  value!: number;

  @field('total_answer')
  total_answer!: number;

  @field('order')
  order!: number;

  @field('created_by')
  created_by!: string;

  @field('updated_by')
  updated_by!: string;

  @field('created_at')
  created_at!: number;

  @field('updated_at')
  updated_at!: number;
}
