import { appSchema } from '@nozbe/watermelondb';

import { answerSchema } from './answerSchema';
import { audioSchema } from './audioSchema';
import { conditionalSchema } from './conditionalSchema';
import { formSchema } from './formSchema';
import { interviewSchema } from './interviewSchema';
import { operationalSessionSchema } from './operationalSessionSchema';
import { projectSchema } from './projectSchema';
import { questionSchema } from './questionSchema';
import { quotaSchema } from './quotaSchema';
import { sampleSchema } from './sampleSchema';
import { sampleToAnswerSchema } from './sampleToAnswerSchema';
import { stepSchema } from './stepSchema';
import { typeAnswerSchema } from './typeAnswerSchema';
import { userSchema } from './userSchema';

export const schemas = appSchema({
  version: 14,
  tables: [
    userSchema,
    conditionalSchema,
    formSchema,
    operationalSessionSchema,
    projectSchema,
    questionSchema,
    quotaSchema,
    sampleSchema,
    sampleToAnswerSchema,
    stepSchema,
    typeAnswerSchema,
    interviewSchema,
    answerSchema,
    audioSchema,
  ],
});
