import { StyleSheet } from 'react-native';

import {
  Border,
  Color,
  FontFamily,
  FontSize,
  Padding,
} from '@/styles/GlobalStyles';

export const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: Color.gray[50],
    marginTop: 48,
    gap: 48,
    paddingHorizontal: '9%',
    paddingVertical: 0,
    alignSelf: 'stretch',
  },
  textContainer: {
    flexDirection: 'column',
    alignItems: 'flex-start',
    gap: 18,
  },
  greetingUser: {
    color: Color.gray[400],
    fontSize: 20,
    fontFamily: FontFamily.bold,
    lineHeight: 24,
  },
  message: {
    color: Color.gray[200],
    fontSize: FontSize.base,
    fontFamily: FontFamily.medium,
    alignItems: 'center',
    width: 234,
  },
  surveyContainer: {
    flex: 1,
    gap: 16,
  },
  title: {
    color: Color.gray[400],
    fontFamily: FontFamily.semiBold,
    fontSize: FontSize.base,
    lineHeight: 24,
  },
  listQuestions: {
    alignSelf: 'stretch',
    flex: 1,
    gap: 16,
  },
  listHeader: {
    borderRadius: Border.lg,
    height: 40,
    gap: 46,
  },
  row: {
    paddingRight: Padding.p_5xs,
    paddingLeft: Padding.p_base,
    flexDirection: 'row',
    backgroundColor: Color.gray.white,
    alignSelf: 'stretch',
    alignItems: 'center',
  },
  listText: {
    color: Color.gray[200],
    fontSize: FontSize.xs,
    lineHeight: 24,
  },
  listHeaderText: {
    fontFamily: FontFamily.regular,
    width: 86,
    alignItems: 'center',
  },
  listItem: {
    borderRadius: 6,
    shadowRadius: 22,
    elevation: 22,
    height: 60,
    gap: 46,
    boxShadow: '0px 4px 22px 0px rgba(37, 38, 94, 0.10)',
  },
  firstListItem: {
    alignItems: 'center',
    color: Color.gray[300],
    fontFamily: FontFamily.semiBold,
    width: 86,
    lineHeight: 14,
  },

  centeredView: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
    marginTop: 22,
  },
  modalView: {
    margin: 20,
    backgroundColor: 'white',
    borderRadius: 20,
    padding: 35,
    alignItems: 'center',
    shadowColor: '#000',
    shadowOffset: {
      width: 0,
      height: 2,
    },
    shadowOpacity: 0.25,
    shadowRadius: 4,
    elevation: 5,
  },
  button: {
    borderRadius: 20,
    padding: 10,
    elevation: 2,
  },
  buttonOpen: {
    backgroundColor: '#F194FF',
  },
  buttonClose: {
    backgroundColor: '#2196F3',
  },
  textStyle: {
    color: 'white',
    fontWeight: 'bold',
    textAlign: 'center',
  },
  modalText: {
    marginBottom: 15,
    textAlign: 'center',
  },
  versionText: {
    // position: 'absolute',
    // bottom: -30, // Posiciona o texto na parte inferior
    // right: 10, // Mantém à direita
    fontSize: FontSize.xs, // Ajuste o tamanho da fonte conforme necessário
    textAlign: 'right',
    marginBottom: 32,
    // Outros estilos para o texto, se necessário
  },
});
