/* eslint-disable no-underscore-dangle */
/* eslint-disable no-param-reassign */
import { useClerk, useSession, useSignIn } from '@clerk/clerk-react';
import { openDB } from 'idb';
import type { ReactNode } from 'react';
import React, {
  createContext,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import * as Sentry from 'sentry-expo';

import { database } from '@/database';
import type { UserModel } from '@/database/model/User';

interface User {
  id: string;
  user_id: string;
  name: string;
  username: string;
  session_id: string;
  token: string;
}

interface SignInCredentials {
  username: string;
  password: string;
}

interface AuthContextData {
  user: User;
  loading: boolean;
  login: (credentials: SignInCredentials) => Promise<void>;
  logOut: () => Promise<void>;
}

interface AuthProviderProps {
  children: ReactNode;
}

const AuthContext = createContext<AuthContextData>({} as AuthContextData);

export function AuthProvider({ children }: AuthProviderProps) {
  const { signIn, setActive, isLoaded } = useSignIn();
  const { signOut } = useClerk();
  const { session } = useSession();

  const [data, setData] = useState<User>({} as User);

  const [loading, setLoading] = useState(false);

  const createUser = useMemo(async () => {
    if (
      session &&
      data.session_id !== session.id &&
      session.user.publicMetadata.role === 'Entrevistador'
    ) {
      let userData: UserModel;
      try {
        const userCollection = database.get<UserModel>('users');
        await database.write(async () => {
          userData = await userCollection.create((newUser) => {
            newUser.user_id = session?.user.id as string;
            newUser.name = session?.user.firstName! as string;
            newUser.username = session?.user.username! as string;
            newUser.token = session?.id as string;
            newUser.session_id = session?.id as string;
            newUser.role = session?.user.publicMetadata.role as string;
          });
          setData(userData._raw as unknown as User);
          setLoading(false);
          Sentry.Browser.setUser({
            id: session?.user.id,
            username: session?.user.username || '',
          });
        });
      } catch (error) {
        const userCollection = database.get<UserModel>('users');
        await database.write(async () => {
          const userSelected = await userCollection.find(userData.id);
          await userSelected.destroyPermanently();
        });
        signOut();
      } finally {
        setLoading(false);
      }
    }
  }, [session]);

  async function login({ username, password }: SignInCredentials) {
    setLoading(true);

    if (!isLoaded) {
      return;
    }

    try {
      const completeSignIn = await signIn.create({
        identifier: username,
        password,
      });

      await setActive({ session: completeSignIn.createdSessionId });
      await createUser;
    } catch (error: any) {
      setLoading(false);
      Sentry.Browser.setUser({ email: username });
      Sentry.Browser.captureException(error);
      throw new Error(error);
    }
  }

  const initDB = async () => {
    const db = await openDB('AudioDB', 1, {
      upgrade(dbUpgrade) {
        if (!dbUpgrade.objectStoreNames.contains('recordings')) {
          dbUpgrade.createObjectStore('recordings', {
            keyPath: 'id',
            autoIncrement: true,
          });
        }
      },
    });
    return db;
  };

  async function logOut() {
    try {
      const db = await initDB();
      const tx = db.transaction('recordings', 'readwrite');
      const store = tx.objectStore('recordings');
      store.clear();
      await tx.done;
      await database.write(async () => {
        database.unsafeResetDatabase();
      });
      setData({} as User);
      signOut();
    } catch (error) {
      console.log(error);
    }
  }

  useEffect(() => {
    async function loadUserData() {
      const userCollection = database.get<UserModel>('users');
      const response = await userCollection.query().fetch();

      if (response.length > 0) {
        const userData = response[0]._raw as unknown as User;

        setData(userData);
      }
    }

    loadUserData();
  }, []);

  const value = useMemo(() => {
    return {
      user: data,
      login,
      logOut,
      loading,
    };
  }, [data, loading, session]);

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
}

export function useAuth(): AuthContextData {
  const context = useContext(AuthContext);

  return context;
}
