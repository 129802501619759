import { Model } from '@nozbe/watermelondb';
import { field } from '@nozbe/watermelondb/decorators';

export class ProjectModel extends Model {
  static table = 'projects';

  @field('project_id')
  project_id!: number;

  @field('name')
  name!: string;

  @field('created_by')
  created_by!: string;

  @field('updated_by')
  updated_by!: string;

  @field('created_at')
  created_at!: number;

  @field('updated_at')
  updated_at!: number;
}
