import { StyleSheet } from 'react-native';

import { Color, FontFamily, FontSize } from '@/styles/GlobalStyles';

export const styles = StyleSheet.create({
  container: {
    flex: 1,
    alignSelf: 'stretch',
    gap: 14,
  },
  tabBarContainer: {
    backgroundColor: 'transparent',
    border: `1px solid ${Color.gray[50]}`,
  },
  tabBarIndicatorStyle: {
    backgroundColor: Color.primary[300],
  },
  tabLabel: {
    color: Color.gray[400],
    fontFamily: FontFamily.medium,
    fontSize: FontSize.sm,
    lineHeight: 20,
  },
});
