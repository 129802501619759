import { Model } from '@nozbe/watermelondb';
import { field } from '@nozbe/watermelondb/decorators';

export class QuestionModel extends Model {
  static table = 'questions';

  @field('question_id')
  question_id!: number;

  @field('name')
  name!: string;

  @field('order')
  order!: number;

  @field('description')
  description!: string;

  @field('is_dropdown')
  is_dropdown!: boolean;

  @field('is_random_order_answer')
  is_random_order_answer!: boolean;

  @field('step_id')
  step_id!: number;

  @field('created_by')
  created_by!: string;

  @field('updated_by')
  updated_by!: string;

  @field('created_at')
  created_at!: number;

  @field('updated_at')
  updated_at!: number;
}
