import { StyleSheet } from 'react-native';

import { Color, FontFamily, FontSize } from '@/styles/GlobalStyles';

export const styles = StyleSheet.create({
  infoText: {
    color: Color.darkSlateGray_100,
    textAlign: 'left',
    lineHeight: 24,
    fontFamily: FontFamily.regular,
    fontSize: FontSize.sm,
  },
});
