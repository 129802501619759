import React, { useContext, useEffect, useState } from 'react';
import { FlatList, View } from 'react-native';
import { RadioButton as RNPRadioButton } from 'react-native-paper';

import { InterviewContext } from '@/contexts/InterviewContext';
import type { TypeAnswerModel } from '@/database/model/TypeAnswer';
import { Color, Spacing } from '@/styles/GlobalStyles';

import { optionSelected, styles } from './styles';

interface IProps {
  values: TypeAnswerModel[];
  onChange: (value: TypeAnswerModel) => void;
}

export function RadioButton({ values, onChange }: IProps) {
  const [optionsValue, setOptionsValue] = useState('');

  const { answeredQuestions, formData } = useContext(InterviewContext);

  useEffect(() => {
    const isAnswered = answeredQuestions.find(
      (answer) => answer.question_id === values[0].question_id,
    );
    if (isAnswered && formData) {
      const answerId = isAnswered.values[0];
      formData.steps.forEach((step) => {
        const answerQuestion = step.questions.find(
          (question) => question.id === values[0].question_id,
        );
        if (answerQuestion) {
          const answerValue = answerQuestion.value.find(
            (answer) => answer.id === answerId,
          );
          setOptionsValue(answerValue ? answerValue.value : '');
        }
      });
    }
  }, []);

  const changeValue = (value: string) => {
    const dataToSave = values.find(
      (question) => question.value === value,
    ) as TypeAnswerModel;
    setOptionsValue(value);
    onChange(dataToSave);
  };

  return (
    <RNPRadioButton.Group value={optionsValue} onValueChange={changeValue}>
      <View style={styles.container}>
        <FlatList
          data={values}
          style={{ gap: 12 }}
          renderItem={({ item }) => (
            <RNPRadioButton.Item
              label={item.value}
              labelStyle={styles.labelOption}
              style={[
                {
                  padding: Spacing.xs2,
                  minHeight: 'auto',
                  justifyContent: 'flex-start',
                },
                optionsValue === item.value
                  ? optionSelected
                  : styles.optionNotSelected,
              ]}
              position="leading"
              color={Color.primary[300]}
              uncheckedColor={Color.gray[200]}
              value={item.value}
            />
          )}
        />
      </View>
    </RNPRadioButton.Group>
  );
}
