import { hasUnsyncedChanges } from '@nozbe/watermelondb/sync';
import { useNetInfo } from '@react-native-community/netinfo';
import type { DrawerNavigationProp } from '@react-navigation/drawer';
import type { ParamListBase } from '@react-navigation/native';
import { ArrowLeftFromLine } from 'lucide-react-native';
import React, { useState } from 'react';
import type { StyleProp, ViewStyle } from 'react-native';
import { Modal, Pressable, Text, View } from 'react-native';

import { database } from '@/database';
import { useAuth } from '@/hooks/auth';

import { styles } from './styles';

type LogoutItemType = {
  navigation: DrawerNavigationProp<ParamListBase>;
  style?: StyleProp<ViewStyle>;
  isSelected?: boolean;
};

export function LogoutItem({ navigation, style, isSelected }: LogoutItemType) {
  const netInfo = useNetInfo();
  const { logOut } = useAuth();

  const [modalVisible, setModalVisible] = useState(false);
  const [modalText, setModalText] = useState('');

  const iconColor = isSelected ? styles.selectedIcon : styles.notSelectedIcon;

  const handleSignOut = async () => {
    const isUnSynced = await hasUnsyncedChanges({ database });
    if (isUnSynced) {
      setModalText('Existem entrevistas que precisam ser sincronizadas.');
      return setModalVisible(true);
    }
    if (!netInfo.isConnected) {
      navigation.toggleDrawer();
      return alert('Você precisa estar conectado para fazer sair.');
    }

    navigation.toggleDrawer();
    return logOut();
  };

  return (
    <>
      <Pressable
        style={[styles.buttonContainer, style]}
        onPress={handleSignOut}
      >
        <ArrowLeftFromLine style={{ ...styles.icon, ...iconColor }} />
        <Text style={[styles.buttonText, style]}>Sair</Text>
      </Pressable>
      <Modal
        animationType="fade"
        transparent
        visible={modalVisible}
        onRequestClose={() => {
          navigation.navigate('home');
        }}
      >
        <View style={styles.centeredView}>
          <View style={styles.modalView}>
            <Text style={styles.modalText}>{modalText}</Text>
            <Pressable
              style={[styles.button, styles.buttonClose]}
              onPress={() => {
                setModalVisible(!modalVisible);
              }}
            >
              <Text style={styles.textStyle}>Voltar</Text>
            </Pressable>
          </View>
        </View>
      </Modal>
    </>
  );
}
