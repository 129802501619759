import { tableSchema } from '@nozbe/watermelondb';

import type { ITable } from './interface';

export const quotaTable = {
  name: 'quotas',
  columns: [
    {
      name: 'quota_id',
      type: 'number',
    },
    {
      name: 'name',
      type: 'string',
    },
    {
      name: 'form_id',
      type: 'number',
    },
    {
      name: 'form_version',
      type: 'string',
    },
    {
      name: 'operational_session_id',
      type: 'string',
    },
    {
      name: 'questions_ids',
      type: 'string',
    },
    {
      name: 'step_id',
      type: 'number',
    },
    {
      name: 'created_by',
      type: 'string',
    },
    {
      name: 'updated_by',
      type: 'string',
    },
    {
      name: 'created_at',
      type: 'number',
    },
    {
      name: 'updated_at',
      type: 'number',
    },
  ],
} as ITable;

export const quotaSchema = tableSchema(quotaTable);
