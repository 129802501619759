import { StyleSheet } from 'react-native';

import {
  Border,
  Color,
  FontFamily,
  FontSize,
  Padding,
  Spacing,
} from '@/styles/GlobalStyles';

export const styles = StyleSheet.create({
  formContainer: {
    gap: 12,
    flex: 1,
  },
  container: {
    paddingVertical: Padding.p_xl,
    paddingHorizontal: 17,
    elevation: Spacing.xl,
    shadowRadius: Spacing.xl,
    shadowColor: Color.gray.opacity_08_black,
    backgroundColor: Color.gray.white,
    shadowOpacity: 1,
    shadowOffset: {
      width: 0,
      height: 4,
    },
    borderRadius: Border.lg,
    alignSelf: 'stretch',
    gap: 10,
  },
  titleContainer: {
    alignItems: 'center',
    alignSelf: 'stretch',
    flexDirection: 'row',
  },
  numberContainer: {
    flex: 1,
  },
  number: {
    // color: Color.dimGray,
    color: Color.darkSlateGray_100,
    textAlign: 'left',
    lineHeight: 24,
    fontFamily: FontFamily.regular,
    fontSize: FontSize.sm,
    // textTransform: 'uppercase',
  },
  numberArrowIcon: {
    height: 20,
    width: 20,
    justifyContent: 'center',
    alignItems: 'center',
  },
  arrowIcon: {
    width: 12,
    height: 6,
  },
  questionsContainer: {
    alignSelf: 'stretch',
    gap: Spacing.xl,
  },
  question: {
    lineHeight: 19,
    fontFamily: FontFamily.semiBold,
    color: Color.darkSlateGray_100,
    fontSize: FontSize.base,
  },
  optionsContainer: {
    gap: Spacing.xs,
  },
  labelOption: {
    flexGrow: 0,
    fontFamily: FontFamily.regular, // 400
    fontSize: FontSize.base,
  },
  row: {
    paddingRight: Padding.p_5xs,
    paddingLeft: Padding.p_base,
    flexDirection: 'row',
    backgroundColor: Color.gray.white,
    alignSelf: 'stretch',
    alignItems: 'center',
  },
  listText: {
    color: Color.gray[200],
    fontSize: FontSize.xs,
    lineHeight: 24,
  },
  listItem: {
    borderRadius: 6,
    shadowColor: 'rgba(37, 38, 94, 0.1)',
    shadowRadius: 22,
    elevation: 22,
    height: 60,
    gap: 46,
    boxShadowOpacity: 1,
    boxShadowOffset: {
      width: 0,
      height: 4,
    },
  },
  firstListItem: {
    alignItems: 'center',
    color: Color.gray[300],
    fontFamily: FontFamily.semiBold,
    width: 86,
    lineHeight: 14,
  },
  fixedButton: {
    borderBottomWidth: 1,
    borderColor: Color.gray[100],
    borderStyle: 'solid',
    borderRightWidth: 1,
    backgroundColor: Color.gray.white,
    boxShadow: '0px -4px 4px 0px rgba(0, 0, 0, 0.08)',
    elevation: 16,
    paddingVertical: Padding.p_xl,
    paddingHorizontal: '9%',
    width: '100%',
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  buttons: {
    paddingHorizontal: Padding.p_5xl,
    paddingVertical: 10,
    width: '42%',
    maxWidth: 200,
    flexDirection: 'column',
    alignSelf: 'stretch',
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: 6,
    gap: 46,
    boxShadowOpacity: 1,
    boxShadowOffset: {
      width: 0,
      height: 4,
    },
  },
  nextButton: {
    shadowRadius: 22,
    elevation: 22,
    shadowColor: 'rgba(37, 38, 94, 0.1)',
  },
  saveButton: {
    backgroundColor: Color.primary[400],
  },
  disabledSaveButton: {
    backgroundColor: Color.gray['100'],
  },
  prevButton: {
    borderWidth: 1,
    borderColor: Color.primary[300],
    backgroundColor: 'rgba(52, 52, 52, 0.0)',
  },
  prevButtonText: {
    fontSize: FontSize.sm,
    color: Color.primary[300],
    fontFamily: FontFamily.medium, // 500
  },
  saveButtonTextDefault: {
    fontSize: FontSize.sm,
    fontFamily: FontFamily.medium, // 500
    verticalAlign: 'middle',
  },
  saveButtonText: {
    color: 'white',
  },
  saveButtonDisabledText: {
    color: Color.gray[200],
  },
  quotaAlert: {
    paddingRight: Padding.p_5xs,
    paddingLeft: Padding.p_base,
    flexDirection: 'column',
    alignSelf: 'stretch',
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: 6,
    backgroundColor: '#ff0005',
    shadowColor: 'rgba(37, 38, 94, 0.1)',
    shadowRadius: 22,
    elevation: 22,
    height: 38,
    gap: 46,
    boxShadowOpacity: 1,
    boxShadowOffset: {
      width: 0,
      height: 4,
    },
  },
  centeredView: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
    marginTop: 22,
  },
  modalView: {
    margin: 20,
    backgroundColor: 'white',
    borderRadius: 20,
    padding: 35,
    alignItems: 'center',
    shadowColor: '#000',
    shadowOffset: {
      width: 0,
      height: 2,
    },
    shadowOpacity: 0.25,
    shadowRadius: 4,
    elevation: 5,
  },
  button: {
    borderRadius: 20,
    padding: 10,
    elevation: 2,
  },
  buttonOpen: {
    backgroundColor: '#F194FF',
  },
  buttonClose: {
    backgroundColor: '#2196F3',
  },
  textStyle: {
    color: 'white',
    fontWeight: 'bold',
    textAlign: 'center',
  },
  modalText: {
    marginBottom: 15,
    textAlign: 'center',
  },
  closeDropdownButtonContainer: {
    position: 'fixed',
    top: '44%',
    backgroundColor: 'white',
    width: '100%',
    left: 0,
    paddingTop: 12,
    paddingBottom: 8,
    borderRadius: 4,
  },
  closeDropdownButton: {
    paddingRight: Padding.p_5xs,
    paddingLeft: Padding.p_base,
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: 6,
    width: '49%',
    backgroundColor: Color.primary[400],
    shadowColor: 'rgba(37, 38, 94, 0.1)',
    shadowRadius: 22,
    elevation: 22,
    height: 40,
    margin: 'auto',
  },
  closeDropdownText: {
    fontFamily: FontFamily.semiBold,
    color: Color.gray.white,
  },
  orderMultiple: {
    padding: 10,
    height: 24,
    width: 24,
    position: 'absolute',
    backgroundColor: Color.primary[400],
    color: 'white',
    fontFamily: FontFamily.regular,
    fontSize: FontSize.base,
    top: '25%',
    right: '1%',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: 4,
  },
});
