import { Model } from '@nozbe/watermelondb';
import { field } from '@nozbe/watermelondb/decorators';

export class SampleToAnswerModel extends Model {
  static table = 'samples_to_answers';

  @field('answer_id')
  answer_id!: number;

  @field('sample_id')
  sample_id!: number;

  @field('order')
  order!: number;

  @field('created_by')
  created_by!: string;

  @field('updated_by')
  updated_by!: string;

  @field('created_at')
  created_at!: number;

  @field('updated_at')
  updated_at!: number;
}
