import { StyleSheet } from 'react-native';

import {
  Color,
  FontFamily,
  FontSize,
  Padding,
  Stroke,
} from '@/styles/GlobalStyles';

export const styles = StyleSheet.create({
  container: {
    backgroundColor: Color.gray.white,
    shadowColor: 'rgba(0, 0, 0, 0.1)',
    shadowOffset: {
      width: 0,
      height: 4,
    },
    shadowRadius: 22,
    elevation: 22,
    shadowOpacity: 1,
    justifyContent: 'center',
    paddingHorizontal: '9%',
    paddingVertical: Padding.p_xs,
    alignSelf: 'stretch',
    gap: 10,
  },
  interviewInfoContainer: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  name: {
    fontSize: FontSize.lg,
    lineHeight: 24,
    fontWeight: '600',
    fontFamily: FontFamily.semiBold,
    color: Color.gray[400],
    textAlign: 'left',
  },
  trashContainer: {
    backgroundColor: Color.secondary['red-0_12'],
    padding: 10,
    borderRadius: 999,
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
  },
  trashIcon: {
    width: 20,
    height: 20,
    color: Color.secondary.red,
    strokeWidth: Stroke['1.2'],
  },
});
