import { Model } from '@nozbe/watermelondb';
import { field } from '@nozbe/watermelondb/decorators';

export class FormModel extends Model {
  static table = 'forms';

  @field('form_id')
  form_id!: number;

  @field('form_version')
  form_version!: string;

  @field('name')
  name!: string;

  @field('sesion_id')
  sesion_id!: string;

  @field('is_template')
  is_template!: boolean;

  @field('archived')
  archived!: boolean;

  @field('created_by')
  created_by!: string;

  @field('updated_by')
  updated_by!: string;

  @field('archived_by')
  archived_by!: string;

  @field('created_at')
  created_at!: number;

  @field('updated_at')
  updated_at!: number;
}
