import React from 'react';
import type { TextInputProps } from 'react-native-paper';
import { TextInput as RNPTextInput } from 'react-native-paper';

import { Color, FontFamily } from '@/styles/GlobalStyles';

type ITextInputProps = Partial<TextInputProps> & {
  placeholder?: string;
  value: string;
  onChangeText: (text: string) => void;
};

export function TextInput({
  placeholder,
  value,
  onChangeText,
  // eslint-disable-next-line unused-imports/no-unused-vars
  theme,
  ...props
}: ITextInputProps) {
  return (
    <RNPTextInput
      {...props}
      mode="outlined"
      placeholder={placeholder}
      activeOutlineColor={Color.primary[300]}
      placeholderTextColor={Color.gray[200]}
      outlineColor={Color.gray[100]}
      value={value}
      onChangeText={(text) => onChangeText(text)}
      theme={{
        fonts: {
          regular: { fontFamily: FontFamily.regular },
        },
        colors: { text: Color.gray[400] },
        ...theme,
      }}
    />
  );
}
