import { StyleSheet } from 'react-native';

import { Color, FontFamily, FontSize } from '@/styles/GlobalStyles';

export const styles = StyleSheet.create({
  container: {
    flex: 1,
    gap: 20,
  },
  lastSync: {
    color: Color.gray[300],
    fontFamily: FontFamily.regular,
    fontSize: FontSize.sm,
    fontStyle: 'italic',
    lineHeight: 24,
    textAlign: 'center',
  },
  listContainer: {
    gap: 16,
    marginBottom: 20,
  },
  allSynchronizedContainer: {
    alignItems: 'center',
    flexDirection: 'row',
    justifyContent: 'center',
    marginTop: 28,

    border: `2px dashed ${Color.gray[100]}`,
    padding: 40,
    borderRadius: 8,
  },
  allSynchronizedText: {
    color: Color.gray[400],
    fontFamily: FontFamily.regular,
    fontSize: FontSize.sm,
    lineHeight: 24,
  },
});
