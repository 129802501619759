import type { SyncStatus } from '@nozbe/watermelondb/Model';
import { CheckCircle2, CircleEllipsis, XCircle } from 'lucide-react-native';
import React, { memo } from 'react';
import { Text, View } from 'react-native';

import { useSyncContext } from '@/contexts/syncContext';
import { Color } from '@/styles/GlobalStyles';

import { styles } from './styles';

type ISyncCardComponentProps = {
  id: string;
  formName: string;
  startDate: string;
  duration: string;
  status: SyncStatus;
  discarded: string;
};

export function CardComponent({
  id,
  formName,
  startDate,
  duration,
  status,
  discarded,
}: ISyncCardComponentProps) {
  const { isSyncing } = useSyncContext();

  const statusIcon = () => {
    if (status === 'synced') {
      return (
        <View style={styles.statusContainer}>
          <CheckCircle2
            style={styles.statusIcon}
            fill={Color.secondary.green}
          />
          <Text style={[styles.textDefault, styles.textNormal]}>Enviada</Text>
        </View>
      );
    }

    if (isSyncing) {
      return (
        <View style={styles.statusContainer}>
          <CircleEllipsis
            style={styles.statusIcon}
            fill={Color.secondary.yellow}
          />
          <Text style={[styles.textDefault, styles.textNormal]}>
            Sincronizando
          </Text>
        </View>
      );
    }

    return (
      <View style={styles.statusContainer}>
        <XCircle style={styles.statusIcon} fill={Color.secondary.red} />
        <Text style={[styles.textDefault, styles.textNormal]}>
          Falha de envio
        </Text>
      </View>
    );
  };

  return (
    <View style={styles.container}>
      <View style={styles.idFormNameContainer}>
        <Text style={[styles.textDefault, styles.textSemiBold, styles.id]}>
          ID {id}
        </Text>
        <Text style={[styles.textDefault, styles.textSemiBold]}>
          {formName}
        </Text>
      </View>

      <View style={styles.timeContainer}>
        <View style={styles.timeContainer}>
          <Text style={[styles.textDefault, styles.textSemiBold]}>Inicio:</Text>
          <Text style={[styles.textDefault, styles.textNormal]}>
            {startDate}
          </Text>
        </View>
        <View style={styles.timeContainer}>
          <Text style={[styles.textDefault, styles.textSemiBold]}>
            Duração:
          </Text>
          <Text style={[styles.textDefault, styles.textNormal]}>
            {duration}
          </Text>
        </View>
      </View>

      <View style={styles.timeContainer}>
        <Text style={[styles.textDefault, styles.textSemiBold]}>
          Descartada:
        </Text>
        <Text style={[styles.textDefault, styles.textNormal]}>{discarded}</Text>
      </View>

      <View style={styles.statusContainer}>
        <Text style={[styles.textDefault, styles.textNormal]}>
          {statusIcon()}
        </Text>
      </View>
    </View>
  );
}

export const Card = memo(CardComponent);
