import React, { memo } from 'react';
import {
  FlatList,
  ScrollView,
  Text,
  useWindowDimensions,
  View,
} from 'react-native';

import { DateFormatter } from '@/helpers/DateFormatter';
import type { ISyncList } from '@/libs/types/sync';

import { Row } from '../Row';
import { styles } from './styles';

type ITableProps = {
  interviews: ISyncList[];
};

function TableComponent({ interviews }: ITableProps) {
  const { width } = useWindowDimensions();
  const isMobile = width < 540;

  return (
    <ScrollView
      contentContainerStyle={styles.container}
      showsVerticalScrollIndicator={false}
      showsHorizontalScrollIndicator={false}
      style={{
        paddingRight: isMobile ? '9%' : 0,
        width: isMobile ? 'max-content' : '100%',
      }}
    >
      <View style={styles.rows}>
        <View style={[styles.headerCells, styles.headerCellId]}>
          <Text style={styles.text}>ID</Text>
        </View>
        <View style={[styles.headerCells]}>
          <Text style={styles.text}>Questionário</Text>
        </View>
        <View style={[styles.headerCells]}>
          <Text style={styles.text}>Data de Envio</Text>
        </View>
        <View style={[styles.headerCells]}>
          <Text style={styles.text}>Duração</Text>
        </View>
        <View style={[styles.headerCells, styles.headerCellStatus]}>
          <Text style={styles.text}>Status</Text>
        </View>
      </View>
      <FlatList
        data={interviews}
        keyExtractor={(item) => item.id}
        style={{
          width: '100%',
          marginTop: 40,
        }}
        renderItem={({ item }) => {
          const {
            interview_id: interviewId,
            form_name: formName,
            created_at: createdAt,
            updated_at: updatedAt,
            is_discarted: isDiscarded,
          } = item;
          const startDate =
            DateFormatter.fullDateWithHoursAndMinutes(createdAt);
          const duration = DateFormatter.duration({
            createdAt,
            updatedAt,
            options: ['hours', 'minutes', 'seconds'],
          });
          const status = isDiscarded ? 'Descartada' : 'Concluído';

          return (
            <Row
              id={interviewId}
              form={formName}
              startDate={startDate}
              duration={duration}
              status={status}
            />
          );
        }}
        showsVerticalScrollIndicator={false}
      />
    </ScrollView>
  );
}

export const Table = memo(TableComponent);
