import { Image } from 'expo-image';
import React, { useState } from 'react';
import { Pressable, View } from 'react-native';

import logoFutura from '@/assets/logo-futura-azul-escuro12.png';
import xMarkLargeImage from '@/assets/xmark-large.png';
import { Color, FontFamily } from '@/styles/GlobalStyles';

import { DashboardItem } from './components/DashboardItem';
import { HomeItem } from './components/HomeItem';
import { LogoutItem } from './components/LogoutItem';
import { ProfileItem } from './components/ProfileItem';
import { SyncItem } from './components/SyncItem';
import { styles } from './styles';

type MenuType = {
  state?: any;
  navigation?: any;
};

const normalStyle = {
  backgroundColor: 'transparent',
  fontFamily: FontFamily.regular,
  color: Color.dimGray,
};

export function Menu({ state, navigation }: MenuType) {
  const [drawerItemsNormal] = useState([
    <HomeItem style={normalStyle} />,
    <SyncItem style={normalStyle} />,
    <DashboardItem style={normalStyle} />,
    <ProfileItem style={normalStyle} />,
    <LogoutItem style={normalStyle} navigation={navigation} />,
  ]);
  const [drawerItemsActive] = useState([
    <HomeItem isSelected />,
    <SyncItem isSelected />,
    <DashboardItem isSelected />,
    <ProfileItem isSelected />,
    <LogoutItem navigation={navigation} isSelected />,
  ]);
  const stateIndex = !state ? 0 : state.index;

  return (
    <View style={styles.container}>
      <View style={styles.imagesContainer}>
        <Image style={styles.logoMenu} contentFit="cover" source={logoFutura} />
        <Pressable
          style={({ pressed }) => [{ opacity: pressed ? 0.2 : 1 }]}
          onPress={() => navigation.toggleDrawer()}
        >
          <Image
            style={styles.closeMenu}
            contentFit="cover"
            source={xMarkLargeImage}
          />
        </Pressable>
      </View>
      <View style={styles.options}>
        {stateIndex === 0 ? drawerItemsActive[0] : drawerItemsNormal[0]}
        {stateIndex === 1 ? drawerItemsActive[1] : drawerItemsNormal[1]}
        {stateIndex === 2 ? drawerItemsActive[2] : drawerItemsNormal[2]}
        {stateIndex === 3 ? drawerItemsActive[3] : drawerItemsNormal[3]}
        {stateIndex === 4 ? drawerItemsActive[4] : drawerItemsNormal[4]}
      </View>
    </View>
  );
}
