import { Model } from '@nozbe/watermelondb';
import { field } from '@nozbe/watermelondb/decorators';

export class QuotaModel extends Model {
  static table = 'quotas';

  @field('quota_id')
  quota_id!: number;

  @field('name')
  name!: string;

  @field('form_id')
  form_id!: number;

  @field('form_version')
  form_version!: string;

  @field('operational_session_id')
  operational_session_id!: string;

  @field('questions_ids')
  questions_ids!: string;

  @field('step_id')
  step_id!: number;

  @field('created_by')
  created_by!: string;

  @field('updated_by')
  updated_by!: string;

  @field('created_at')
  created_at!: number;

  @field('updated_at')
  updated_at!: number;
}
