/* eslint-disable no-param-reassign */
import { useNavigation } from '@react-navigation/native';
import React from 'react';
import { Pressable, Text } from 'react-native';

import type { IFormList } from '@/interfaces/formInterfaces';

import { styles } from '../styles';

interface IProps {
  formData: IFormList;
}

export function FormRow({ formData }: IProps) {
  const navigation = useNavigation();
  const press = () => {
    navigation.navigate('interview', {
      formId: formData.form_id,
      formVersion: formData.form_version,
    });
  };
  return (
    <Pressable onPress={press} style={[styles.listItem, styles.row]}>
      <Text
        style={[styles.listText, styles.firstListItem]}
        numberOfLines={3}
        ellipsizeMode="tail"
      >
        {formData.name}
      </Text>
      <Text style={[styles.listText]} numberOfLines={3} ellipsizeMode="tail">
        {formData.sessionName}
      </Text>
    </Pressable>
  );
}
