import { useClerk } from '@clerk/clerk-react';
import { NavigationContainer } from '@react-navigation/native';
import React from 'react';
import { View } from 'react-native';
import * as Sentry from 'sentry-expo';

import { AppProvider } from '@/hooks';
import { Color } from '@/styles/GlobalStyles';

import { SplashRoutes } from './splash.routes';

export function Routes() {
  const { user } = useClerk();

  React.useEffect(() => {
    if (user) {
      Sentry.Browser.setUser({
        id: user.id,
      });
    }
  }, [user]);

  return (
    <View style={{ flex: 1, backgroundColor: Color.gray[50] }}>
      <NavigationContainer>
        <AppProvider>
          <SplashRoutes />
        </AppProvider>
      </NavigationContainer>
    </View>
  );
}
