import { Model } from '@nozbe/watermelondb';
import { field } from '@nozbe/watermelondb/decorators';

export class AudioModel extends Model {
  static table = 'audios';

  @field('audio')
  audio!: string;

  @field('interview_id')
  interview_id!: string;

  @field('created_by')
  created_by!: string;

  @field('updated_by')
  updated_by!: string;

  @field('created_at')
  created_at!: number;

  @field('updated_at')
  updated_at!: number;
}
