import { StyleSheet } from 'react-native';

import {
  Color,
  FontFamily,
  FontSize,
  Padding,
  Stroke,
} from '@/styles/GlobalStyles';

export const styles = StyleSheet.create({
  container: {
    shadowColor: 'rgba(0, 0, 0, 0.1)',
    shadowRadius: 16,
    elevation: 16,
    paddingLeft: '9%',
    paddingTop: Padding.p_xs,
    paddingBottom: Padding.p_xs,
    shadowOpacity: 1,
    shadowOffset: {
      width: 0,
      height: 4,
    },
    backgroundColor: Color.gray.white,
    flexDirection: 'row',
    alignSelf: 'stretch',
    alignItems: 'center',
    gap: 15,
  },
  menuIcon: {
    color: Color.gray[400],
    height: 24,
    width: 24,
    strokeWidth: Stroke['1.5'],
  },
  routeName: {
    color: Color.darkSlateGray_100,
    fontFamily: FontFamily.bold,
    fontSize: FontSize.lg,
  },
  logoHeader: {
    width: 113,
    height: 32,
  },
  icon: {
    width: 16,
    height: 16,
    justifyContent: 'center',
    alignItems: 'center',
  },
  centeredView: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
    marginTop: 22,
  },
  modalView: {
    margin: 20,
    backgroundColor: 'white',
    borderRadius: 20,
    padding: 35,
    alignItems: 'center',
    shadowColor: '#000',
    shadowOffset: {
      width: 0,
      height: 2,
    },
    shadowOpacity: 0.25,
    shadowRadius: 4,
    elevation: 5,
  },
  button: {
    borderRadius: 20,
    padding: 10,
    elevation: 2,
  },
  buttonClose: {
    backgroundColor: '#2196F3',
  },
  textStyle: {
    color: 'white',
    fontWeight: 'bold',
    textAlign: 'center',
  },
  modalText: {
    marginBottom: 15,
    textAlign: 'center',
  },
  syncNumberContainer: {
    backgroundColor: 'red',
    position: 'absolute',
    top: -10,
    right: -8,
    borderRadius: 999,
    height: 20,
    width: 20,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
  },
  syncNumberText: {
    flex: 1,
    alignItems: 'center',
    display: 'flex',
    justifyContent: 'center',
    color: 'white',
    fontSize: 12,
  },
});
