import { tableSchema } from '@nozbe/watermelondb';

import type { ITable } from './interface';

export const conditionalTable = {
  name: 'conditionals',
  columns: [
    {
      name: 'type_answers_id',
      type: 'number',
    },
    {
      name: 'question_id',
      type: 'number',
    },
    {
      name: 'order',
      type: 'number',
    },
    {
      name: 'created_by',
      type: 'string',
    },
    {
      name: 'updated_by',
      type: 'string',
    },
    {
      name: 'created_at',
      type: 'number',
    },
    {
      name: 'updated_at',
      type: 'number',
    },
  ],
} as ITable;

export const conditionalSchema = tableSchema(conditionalTable);
