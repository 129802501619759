import { Q } from '@nozbe/watermelondb';
import { useIsFocused } from '@react-navigation/native';
import React, { memo, useEffect, useState } from 'react';

import { useSyncContext } from '@/contexts/syncContext';
import { database } from '@/database';
import type { FormModel } from '@/database/model/Form';
import type { InterviewModel } from '@/database/model/Interview';
import type { IRawInterview, ISyncList } from '@/libs/types/sync';
import { List } from '@/screens/Sync/components/List';

function SynchronizedComponent() {
  const isFocused = useIsFocused();
  const [synchronized, setSynchronized] = useState<ISyncList[]>([]);
  const { isSyncing } = useSyncContext();

  useEffect(() => {
    const checkSyncData = async () => {
      const interviews = await database
        .get<InterviewModel>('interviews')
        .query();
      const interviewsData = interviews.filter(
        (interview) => interview.syncStatus === 'synced',
      );
      const synced = await Promise.all(
        interviewsData.map(async (interview) => {
          const form = await database
            .get<FormModel>('forms')
            .query(
              Q.and(
                Q.where('form_id', interview.form_id),
                Q.where('form_version', interview.form_version),
              ),
            )
            .fetch();
          return {
            // eslint-disable-next-line no-underscore-dangle
            ...(interview._raw as IRawInterview),
            form_name: form[0]?.name,
          };
        }),
      );
      setSynchronized(
        synced.filter((interview) => {
          // eslint-disable-next-line no-underscore-dangle
          if (interview._status === 'synced') {
            return interview.interview_id !== 0;
          }
          return interview;
        }),
      );
    };
    checkSyncData();
  }, [isFocused, isSyncing]);

  return <List data={synchronized} />;
}

export const Synchronized = memo(SynchronizedComponent);
