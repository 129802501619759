import { Database } from '@nozbe/watermelondb';
import LokiJSAdapter from '@nozbe/watermelondb/adapters/lokijs';

import { models } from './model';
import migrations from './model/migrations';
import { schemas } from './schemas';

const adapter = new LokiJSAdapter({
  schema: schemas,
  useWebWorker: false,
  migrations,
  useIncrementalIndexedDB: true,
});

export const database = new Database({
  adapter,
  modelClasses: models,
});
