import { Image } from 'expo-image';
import { X } from 'lucide-react-native';
import React, { useEffect } from 'react';
import {
  Modal,
  Pressable,
  Text,
  useWindowDimensions,
  View,
} from 'react-native';

import exclamationIcon from '@/assets/exclamation-red.png';

import { styles } from './styles';

interface IInterviewModalProps {
  visible: boolean;
  onClose: () => void;
  onDismiss: () => void;
}

export function InterviewModal({
  visible,
  onClose,
  onDismiss,
}: IInterviewModalProps) {
  const { width } = useWindowDimensions();
  const isMobile = width < 540;

  useEffect(() => {
    // Set the message only when the modal is visible
    if (visible) {
      // setModalText logic here if it's dynamic
    }
  }, [visible]);

  const modalText = 'Você tem certeza que deseja descartar a entrevista?';

  return (
    <Modal
      animationType="fade"
      transparent
      visible={visible}
      onRequestClose={onClose}
    >
      <View style={styles.centeredView}>
        <View style={styles.modalView}>
          <Pressable style={styles.closeContainer} onPress={onClose}>
            <X style={styles.closeIcon} />
          </Pressable>
          <View style={styles.exclamationContainer}>
            <Image
              style={styles.exclamationIcon}
              contentFit="cover"
              source={exclamationIcon}
            />
          </View>
          <Text style={styles.modalText}>{modalText}</Text>
          <View
            style={[
              styles.separator,
              isMobile ? styles.separatorColumn : styles.separatorRow,
            ]}
          >
            <Pressable
              style={[
                styles.button,
                styles.buttonClose,
                isMobile && styles.buttonMobile,
              ]}
              onPress={onClose}
            >
              <Text style={[styles.textStyle, styles.textClose]}>Cancelar</Text>
            </Pressable>
            <Pressable
              style={[
                styles.button,
                styles.buttonDiscard,
                isMobile && styles.buttonMobile,
              ]}
              onPress={onDismiss}
            >
              <Text style={[styles.textStyle, styles.textDiscard]}>
                Descartar
              </Text>
            </Pressable>
          </View>
        </View>
      </View>
    </Modal>
  );
}
