import { Model } from '@nozbe/watermelondb';
import { field } from '@nozbe/watermelondb/decorators';

export class OperationalSessionModel extends Model {
  static table = 'operational_sessions';

  @field('session_id')
  session_id!: string;

  @field('title')
  title!: string;

  @field('project_id')
  project_id!: string;

  @field('type')
  type!: string;

  @field('start_date')
  start_date!: number;

  @field('end_date')
  end_date!: number;

  @field('location')
  location!: string;

  @field('thematic')
  thematic!: string;

  @field('status')
  status!: string;

  @field('archived')
  archived!: boolean;

  @field('created_by')
  created_by!: string;

  @field('updated_by')
  updated_by!: string;

  @field('archived_by')
  archived_by!: string;

  @field('created_at')
  created_at!: number;

  @field('updated_at')
  updated_at!: number;
}
