import { StyleSheet } from 'react-native';

import { Color } from '@/styles/GlobalStyles';

export const styles = StyleSheet.create({
  container: {
    width: '100%',
    backgroundColor: Color.gray[50],
    flex: 1,
    alignItems: 'center',
    justifyContent: 'center',
  },
  logoFutura: {
    width: 353,
    height: 100,
  },
});
