import { StyleSheet } from 'react-native';

import { Color, FontFamily, FontSize } from '@/styles/GlobalStyles';

export const styles = StyleSheet.create({
  container: {
    flexDirection: 'row',
    gap: 17,
    width: '100%',
  },
  containerMobile: {
    flexDirection: 'column',
    gap: 12,
  },
  cardContainer: {
    alignItems: 'center',
    justifyContent: 'center',
    gap: 8,
    width: '49.25%',
    padding: 20,
    borderRadius: 8,
    backgroundColor: Color.gray.white,
    boxShadow:
      '0px 1.85px 6.25px 0px rgba(0, 0, 0, 0.19), 0px 0.5px 1.75px 0px rgba(0, 0, 0, 0.04)',
  },
  cardContainerMobile: {
    width: '100%',
  },
  number: {
    color: Color.gray[400],
    fontFamily: FontFamily.robotoRegular,
    fontSize: FontSize['2_125xl'],
    lineHeight: 36,
  },
  label: {
    color: Color.gray[200],
    fontSize: FontSize.sm,
    lineHeight: 20,
    letterSpacing: 0.25,
  },
});
