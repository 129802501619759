import { useNavigation } from '@react-navigation/native';
import { RefreshCcw } from 'lucide-react-native';
import React from 'react';
import type { StyleProp, ViewStyle } from 'react-native';
import { Pressable, Text } from 'react-native';

import { styles } from './styles';

type SyncItemType = {
  style?: StyleProp<ViewStyle>;
  isSelected?: boolean;
};

export function SyncItem({ style, isSelected }: SyncItemType) {
  const navigation = useNavigation();
  const iconColor = isSelected ? styles.selectedIcon : styles.notSelectedIcon;

  return (
    <Pressable
      style={[styles.buttonContainer, style]}
      onPress={() => navigation.navigate('sync')}
    >
      <RefreshCcw style={{ ...styles.icon, ...iconColor }} />
      <Text style={[styles.buttonText, style]}>Sincronizações</Text>
    </Pressable>
  );
}
