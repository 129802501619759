import { tableSchema } from '@nozbe/watermelondb';

import type { ITable } from './interface';

export const typeAnswerTable = {
  name: 'type_answers',
  columns: [
    {
      name: 'type_answer_id',
      type: 'number',
    },
    {
      name: 'value',
      type: 'string',
    },
    {
      name: 'question_id',
      type: 'number',
    },
    {
      name: 'order',
      type: 'number',
    },
    {
      name: 'is_unique',
      type: 'boolean',
    },
    {
      name: 'pin_answer',
      type: 'boolean',
    },
    {
      name: 'type',
      type: 'string',
    },
    {
      name: 'skip_to_question',
      type: 'number',
      isOptional: true,
    },
    {
      name: 'created_by',
      type: 'string',
    },
    {
      name: 'updated_by',
      type: 'string',
    },
    {
      name: 'created_at',
      type: 'number',
    },
    {
      name: 'updated_at',
      type: 'number',
    },
  ],
} as ITable;

export const typeAnswerSchema = tableSchema(typeAnswerTable);
