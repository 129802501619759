import { tableSchema } from '@nozbe/watermelondb';

import type { ITable } from './interface';

export const answerTable = {
  name: 'answers',
  columns: [
    {
      name: 'interview_id',
      type: 'string',
    },
    {
      name: 'interview_version',
      type: 'string',
    },
    {
      name: 'type_answer_id',
      type: 'number',
    },
    {
      name: 'question_id',
      type: 'number',
    },
    {
      name: 'answer_text',
      type: 'string',
    },
    {
      name: 'session_id',
      type: 'string',
    },
    {
      name: 'form_id',
      type: 'number',
    },
    {
      name: 'form_version',
      type: 'string',
    },
    {
      name: 'is_discarted',
      type: 'boolean',
    },
    {
      name: 'is_quota',
      type: 'boolean',
    },
    {
      name: 'created_by',
      type: 'string',
    },
    {
      name: 'updated_by',
      type: 'string',
    },
    {
      name: 'created_at',
      type: 'number',
    },
    {
      name: 'updated_at',
      type: 'number',
    },
  ],
} as ITable;

export const answerSchema = tableSchema(answerTable);
