import { useClerk } from '@clerk/clerk-react';
import { useNavigation } from '@react-navigation/native';
import { Trash2 } from 'lucide-react-native';
import React, { useContext, useState } from 'react';
import { Pressable, Text, View } from 'react-native';
import { useToast } from 'react-native-toast-notifications';
import * as Sentry from 'sentry-expo';

import { useAudioContext } from '@/contexts/AudioContext';
import { InterviewContext } from '@/contexts/InterviewContext';
import { useSyncContext } from '@/contexts/syncContext';
import { database } from '@/database';
import { FormService } from '@/services/FormService';

import { version as APP_VERSION } from '../../../package.json';
import { InterviewModal } from '../InterviewModal';
import { styles } from './styles';

interface IProps {
  name?: string;
}

export function InterviewHeader({ name }: IProps) {
  const navigation = useNavigation();
  const { resetAnswers, answeredQuestions } = useContext(InterviewContext);
  const { setSync, isSyncing, cancelIsSyncing } = useSyncContext();
  const [modalVisible, setModalVisible] = useState(false);
  const { formData, sampleToUpdate, questionsToRender } =
    useContext(InterviewContext);
  const toast = useToast();
  const { session } = useClerk();
  const { stopAudioRecording } = useAudioContext();
  const handleModalDismiss = async () => {
    const transaction = Sentry.Browser.startTransaction({
      name: 'Save Form Answers',
    });
    Sentry.Browser.configureScope((scope) => scope.setSpan(transaction));

    database
      .write(async () => {
        if (formData) {
          FormService.saveFormAnswers(formData, answeredQuestions).then(
            (interviewId) => {
              stopAudioRecording(interviewId);
            },
          );
          if (sampleToUpdate) {
            Sentry.Browser.captureException(
              'Descarte de entrevista com amostra.',
              {
                extra: {
                  formData,
                  sampleToUpdate,
                  clerkId: session?.user.id,
                  username: session?.user.username,
                  questionsToRender: {
                    ...questionsToRender[0],
                    questionId: questionsToRender[0]?.questions[0]?.id,
                  },
                  appVersion: APP_VERSION,
                },
              },
            );
          }
        }
        return null;
      })
      .catch((error) => {
        Sentry.Browser.captureException(error, {
          level: 'error',
          extra: {
            location: 'InterviewHeader - handleModalDismiss',
            username: session?.user.username,
          },
        });
      });

    const id = !isSyncing
      ? toast.show('Sincronizando...', {
          type: 'info',
          placement: 'top',
          duration: 20000,
        })
      : null;

    setSync()
      .then(() => {
        if (id) {
          toast.update(id, 'Sincronia concluída', {
            type: 'success',

            duration: 4000,
          });
        }
      })
      .catch((error) => {
        Sentry.Browser.captureException(error, {
          level: 'error',
          extra: {
            location: 'InterviewHeader - handleModalDismiss',
            username: session?.user.username,
          },
        });

        if (id) {
          toast.update(id, 'Erro na sincroniza, procure o administrador', {
            type: 'danger',

            duration: 4000,
          });
        }
        cancelIsSyncing();
      });

    Sentry.Browser.addBreadcrumb({
      category: 'navigation',
      message: 'Navigating back to home after modal dismiss',
      level: 'info',
    });
    setModalVisible(false);
    navigation.navigate('home');
    resetAnswers();
  };

  return (
    <View style={styles.container}>
      <View style={styles.interviewInfoContainer}>
        <Text style={styles.name}>{name}</Text>

        <Pressable
          style={styles.trashContainer}
          onPress={() => {
            setModalVisible(true);
          }}
        >
          <Trash2 style={styles.trashIcon} />
        </Pressable>
      </View>

      <InterviewModal
        visible={modalVisible}
        onClose={() => {
          setModalVisible(false);
        }}
        onDismiss={() => {
          handleModalDismiss();
        }}
      />
    </View>
  );
}
