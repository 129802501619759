import { tableSchema } from '@nozbe/watermelondb';

import type { ITable } from './interface';

export const interviewTable = {
  name: 'interviews',
  columns: [
    {
      name: 'interview_id',
      type: 'number',
    },
    {
      name: 'interview_version',
      type: 'string',
    },
    {
      name: 'session_id',
      type: 'string',
    },
    {
      name: 'name',
      type: 'string',
    },
    {
      name: 'form_id',
      type: 'number',
    },
    {
      name: 'form_version',
      type: 'string',
    },
    {
      name: 'is_active',
      type: 'boolean',
    },
    {
      name: 'is_discarted',
      type: 'boolean',
    },
    {
      name: 'created_by',
      type: 'string',
    },
    {
      name: 'updated_by',
      type: 'string',
    },
    {
      name: 'created_at',
      type: 'number',
    },
    {
      name: 'updated_at',
      type: 'number',
    },
    {
      name: 'sample_id',
      type: 'number',
    },
  ],
} as ITable;

export const interviewSchema = tableSchema(interviewTable);
