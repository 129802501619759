import { hasUnsyncedChanges } from '@nozbe/watermelondb/sync';
import type { DrawerNavigationProp } from '@react-navigation/drawer';
import type { ParamListBase } from '@react-navigation/native';
import { useIsFocused, useNavigation } from '@react-navigation/native';
import { Image } from 'expo-image';
import { Menu, MessageSquare, RefreshCw } from 'lucide-react-native';
import React, { useEffect, useState } from 'react';
import { Modal, Pressable, Text, View } from 'react-native';
import { useToast } from 'react-native-toast-notifications';

import logoFutura from '@/assets/logo-futura-azul-escuro12.png';
import { useSyncContext } from '@/contexts/syncContext';
import { database } from '@/database';
import type { InterviewModel } from '@/database/model/Interview';
import { Color } from '@/styles/GlobalStyles';

import { styles } from './styles';

type IHeaderProps = {
  name?: string;
};

export function Header({ name }: IHeaderProps) {
  const [modalVisible, setModalVisible] = useState(false);
  const [modalText, setModalText] = useState('');
  const [hasUnSyncedData, setHasUnSyncedData] = useState(false);
  const [unSyncCount, setUnSyncCount] = useState(0);
  const navigation = useNavigation<DrawerNavigationProp<ParamListBase>>();
  const { isSyncing, setSync, cancelIsSyncing } = useSyncContext();

  const toast = useToast();

  const isFocused = useIsFocused();

  const syncData = async () => {
    const id = toast.show('Sincronizando...', {
      type: 'info',
      placement: 'top',
      duration: 20000,
    });
    setSync()
      .then(() => {
        toast.update(id, 'Sincronia concluída', {
          type: 'success',
          duration: 4000,
        });
        setModalText('Banco de dados sincronizado com sucesso.');
      })
      .catch((error) => {
        toast.update(id, 'Erro na sincroniza, procure o administrador', {
          type: 'danger',
          duration: 4000,
        });
        setModalText(`Não foi possível sincronizar, tente novamente mais tarde.
          Erro: ${error.message}`);
        cancelIsSyncing();
      });
  };

  useEffect(() => {
    const checkSyncData = async () => {
      const verify = await hasUnsyncedChanges({ database });
      setHasUnSyncedData(verify);
      const interviews = await database
        .get<InterviewModel>('interviews')
        .query();
      const interviewsRawData = interviews.map(
        (interview) => interview.syncStatus,
      );
      setUnSyncCount(
        interviewsRawData.filter((interview) => interview !== 'synced').length,
      );
    };
    checkSyncData();
  }, [isFocused, isSyncing]);

  return (
    <View style={styles.container}>
      <View
        style={{ gap: 15, flexDirection: 'row', alignItems: 'center', flex: 1 }}
      >
        <Pressable
          style={({ pressed }) => [{ opacity: pressed ? 0.2 : 1 }]}
          onPress={() => navigation.toggleDrawer()}
        >
          <Menu style={styles.menuIcon} />
        </Pressable>

        {name ? (
          <Text style={styles.routeName}>{name}</Text>
        ) : (
          <Image
            style={styles.logoHeader}
            contentFit="cover"
            source={logoFutura}
          />
        )}
      </View>
      <View style={{ display: 'flex', position: 'relative' }}>
        {hasUnSyncedData && (
          <View style={styles.syncNumberContainer}>
            <Text style={styles.syncNumberText}>
              {unSyncCount > 9 ? '+9' : unSyncCount}
            </Text>
          </View>
        )}
        <MessageSquare size="24" color={Color.dimGray} />
      </View>
      <Pressable
        style={({ pressed }) => [
          { opacity: pressed || isSyncing ? 0.2 : 1 },
          { paddingRight: '9%' },
        ]}
        onPress={syncData}
        disabled={isSyncing}
      >
        <RefreshCw size="24" color={Color.dimGray} />
      </Pressable>

      <Modal
        animationType="fade"
        transparent
        visible={modalVisible}
        onRequestClose={() => {
          navigation.navigate('home');
        }}
      >
        <View style={styles.centeredView}>
          <View style={styles.modalView}>
            <Text style={styles.modalText}>{modalText}</Text>
            <Pressable
              style={[styles.button, styles.buttonClose]}
              onPress={() => {
                setModalVisible(!modalVisible);
              }}
            >
              <Text style={styles.textStyle}>Fechar</Text>
            </Pressable>
          </View>
        </View>
      </Modal>
    </View>
  );
}
