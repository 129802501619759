import { Model } from '@nozbe/watermelondb';
import { field } from '@nozbe/watermelondb/decorators';

export class AnswerModel extends Model {
  static table = 'answers';

  @field('interview_id')
  interview_id!: string;

  @field('interview_version')
  interview_version!: string;

  @field('type_answer_id')
  type_answer_id!: number;

  @field('question_id')
  question_id!: number;

  @field('answer_text')
  answer_text!: string;

  @field('session_id')
  session_id!: string;

  @field('form_id')
  form_id!: number;

  @field('form_version')
  form_version!: string;

  @field('is_discarted')
  is_discarted!: boolean;

  @field('is_quota')
  is_quota!: boolean;

  @field('created_by')
  created_by!: string;

  @field('updated_by')
  updated_by!: string;

  @field('created_at')
  created_at!: number;

  @field('updated_at')
  updated_at!: number;
}
