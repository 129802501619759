import React, { memo } from 'react';
import { FlatList, Text, View } from 'react-native';

import { useSyncContext } from '@/contexts/syncContext';
import { DateFormatter } from '@/helpers/DateFormatter';
import type { ISyncList } from '@/libs/types/sync';
import { Card } from '@/screens/Sync/components/Card';

import { styles } from './styles';

type ISyncListProps = {
  data: ISyncList[];
};

function ListComponent({ data }: ISyncListProps) {
  const { lastUpdate } = useSyncContext();

  const emptyList = () => {
    return (
      <View style={styles.allSynchronizedContainer}>
        <Text>Todas as entrevistas foram enviadas!</Text>
      </View>
    );
  };

  return (
    <View style={styles.container}>
      <Text style={styles.lastSync}>
        Última sincronização:{' '}
        {lastUpdate
          ? `${DateFormatter.fullDate(
              lastUpdate.getTime(),
            )} • ${DateFormatter.hoursAndMinutes(lastUpdate.getTime())}`
          : '-'}
      </Text>

      <FlatList
        data={data}
        contentContainerStyle={styles.listContainer}
        keyExtractor={(item) => item.id}
        renderItem={({ item }) => {
          const {
            interview_id: interviewId,
            form_name: formName,
            created_at: createdAt,
            updated_at: updatedAt,
            _status: status,
            is_discarted: isDiscarded,
          } = item;

          return (
            <Card
              id={interviewId.toString()}
              formName={formName}
              startDate={DateFormatter.fullDateWithHoursAndMinutes(createdAt)}
              duration={DateFormatter.duration({
                createdAt,
                updatedAt,
                options: ['hours', 'minutes', 'seconds'],
              })}
              status={status}
              discarded={isDiscarded ? 'Sim' : 'Não'}
            />
          );
        }}
        ListEmptyComponent={() => emptyList()}
        showsVerticalScrollIndicator={false}
      />
    </View>
  );
}

export const List = memo(ListComponent);
