import { useNavigation } from '@react-navigation/core';
import { StackActions } from '@react-navigation/native';
import { Image } from 'expo-image';
import React, { useEffect } from 'react';
import { View } from 'react-native';
import Animated, {
  Extrapolate,
  interpolate,
  runOnJS,
  useAnimatedStyle,
  useSharedValue,
  withTiming,
} from 'react-native-reanimated';

import logoFutura from '@/assets/logo-futura-azul-escuro.svg';

import { styles } from './styles';

export function SplashScreen() {
  const navigation = useNavigation();
  const splashAnimation = useSharedValue(0);

  const logoStyle = useAnimatedStyle(() => {
    return {
      opacity: interpolate(
        splashAnimation.value,
        [0, 0.125, 25, 45, 50],
        [0, 0.25, 0.5, 0.75, 1],
        Extrapolate.CLAMP,
      ),
      transform: [
        {
          translateX: interpolate(
            splashAnimation.value,
            [0, 50],
            [0, 0],
            Extrapolate.CLAMP,
          ),
        },
      ],
    };
  });

  function startApp() {
    navigation.dispatch(StackActions.replace('root'));
  }

  useEffect(() => {
    let mounted = true;

    splashAnimation.value = withTiming(50, { duration: 1000 }, () => {
      if (mounted) {
        ('worklet');
        runOnJS(startApp)();
      }
    });

    return () => {
      mounted = false;
    };
  }, []);

  return (
    <View style={styles.container}>
      <Animated.View style={logoStyle}>
        <Image
          style={styles.logoFutura}
          contentFit="contain"
          source={logoFutura}
        />
      </Animated.View>
    </View>
  );
}
