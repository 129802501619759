import { StyleSheet } from 'react-native';

import { Color, FontFamily, FontSize, Stroke } from '@/styles/GlobalStyles';

export const styles = StyleSheet.create({
  container: {
    flex: 1,
    gap: 12,
    padding: 12,
    alignContent: 'center',
    justifyContent: 'center',

    backgroundColor: 'white',
    borderRadius: 6,
    elevation: 16,
    boxShadow: '0px 4px 22px 0px rgba(37, 38, 94, 0.10)',
  },
  idFormNameContainer: {
    justifyContent: 'center',
    alignItems: 'flex-start',
    gap: 4,
  },
  id: {
    color: Color.gray[400],
  },
  textDefault: {
    fontSize: FontSize.sm,
  },
  textSemiBold: {
    fontFamily: FontFamily.semiBold,
    color: Color.gray[300],
  },
  timeContainer: {
    flexDirection: 'row',
    gap: 4,
    alignItems: 'center',
    alignSelf: 'stretch',
    flexWrap: 'wrap',
  },
  textNormal: {
    fontFamily: FontFamily.regular,
    color: Color.gray[300],
  },
  statusContainer: {
    alignItems: 'center',
    flexDirection: 'row',
    gap: 8,
  },
  statusIcon: {
    color: Color.gray.white,
    height: 20,
    width: 20,
    strokeWidth: Stroke['1.5'],
  },
});
