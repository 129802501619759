import React from 'react';
import { Checkbox as RNPCheckbox } from 'react-native-paper';

import type { TypeAnswerModel } from '@/database/model/TypeAnswer';
import { Color, Spacing } from '@/styles/GlobalStyles';

import { optionChecked, styles } from './styles';

type ICheckboxProps = {
  label: string;
  value: boolean;
  disabled: boolean;
  onChange: (value: TypeAnswerModel) => void;
  answerData: TypeAnswerModel;
};

export function Checkbox({
  label,
  value,
  answerData,
  onChange,
  disabled = false,
}: ICheckboxProps) {
  return (
    <RNPCheckbox.Item
      label={label}
      labelStyle={styles.labelOption}
      style={[
        { padding: Spacing.xs2, justifyContent: 'flex-start' },
        value ? optionChecked : styles.optionUnchecked,
      ]}
      position="leading"
      color={Color.primary[300]}
      uncheckedColor={Color.gray[200]}
      status={value ? 'checked' : 'unchecked'}
      onPress={() => onChange(answerData)}
      disabled={disabled}
    />
  );
}
