import { Model } from '@nozbe/watermelondb';
import { field } from '@nozbe/watermelondb/decorators';

export class UserModel extends Model {
  static table = 'users';

  @field('user_id')
  user_id!: string;

  @field('name')
  name!: string;

  @field('username')
  username!: string;

  @field('role')
  role!: string;

  @field('token')
  token!: string;

  @field('session_id')
  session_id!: string;
}
