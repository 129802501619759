import type { DrawerContentComponentProps } from '@react-navigation/drawer';
import { createDrawerNavigator } from '@react-navigation/drawer';
import React from 'react';

import { Menu } from '@/components/Menu';
import { Dashboard } from '@/screens/Dashboard';
import { Error } from '@/screens/Error';
import { Home } from '@/screens/Home';
import { Interview } from '@/screens/Interview';
import { Sync } from '@/screens/Sync';

const { Navigator, Screen } = createDrawerNavigator();

function CustomDrawerContent(props: DrawerContentComponentProps) {
  return <Menu {...props} />;
}

export function AppRoutes({ navigation }: any) {
  return (
    <Navigator
      screenOptions={{ headerShown: false, drawerStyle: { width: 258 } }}
      drawerContent={CustomDrawerContent}
    >
      <Screen name="home" component={Home} options={{ headerShown: false }} />
      <Screen name="sync" component={Sync} options={{ headerShown: false }} />
      <Screen
        name="dashboard"
        component={Dashboard}
        options={{ headerShown: false }}
      />
      <Screen
        name="interview"
        component={Interview}
        options={{ headerShown: false }}
      />
      <Screen name="error" component={Error} options={{ headerShown: false }} />
    </Navigator>
  );
}
