/* eslint-disable no-param-reassign */
import { Q } from '@nozbe/watermelondb';

import { database } from '@/database';
import type { AnswerModel } from '@/database/model/Answer';
import type { ConditionalModel } from '@/database/model/Conditional';
import type { FormModel } from '@/database/model/Form';
import type { InterviewModel } from '@/database/model/Interview';
import type { OperationalSessionModel } from '@/database/model/OperationalSession';
import type { QuestionModel } from '@/database/model/Question';
import type { QuotaModel } from '@/database/model/Quota';
import type { SampleModel } from '@/database/model/Sample';
import type { SampleToAnswerModel } from '@/database/model/SampleToAnswer';
import type { StepModel } from '@/database/model/Step';
import type { TypeAnswerModel } from '@/database/model/TypeAnswer';
import type { UserModel } from '@/database/model/User';
import type {
  IFormData,
  IQuestionsToSave,
  IRenderConditions,
  ISample,
} from '@/interfaces/formInterfaces';

export class FormService {
  static async formatQuestions(questions: QuestionModel[]) {
    return Promise.all(
      questions.map(async (question) => {
        const typeAnswersData = await database
          .get<TypeAnswerModel>('type_answers')
          .query(Q.where('question_id', question.question_id));
        const orderedTypeAnswers = typeAnswersData.sort(
          (a, b) => a.order - b.order,
        );

        const conditionalsData = await database
          .get<ConditionalModel>('conditionals')
          .query(Q.where('question_id', question.question_id));

        const formattedConditions = conditionalsData.reduce(
          (acc: IRenderConditions[][], curr: IRenderConditions) => {
            if (curr.order === 0) {
              const nextOrder =
                acc.length > 0 ? acc[acc.length - 1][0].order + 1 : 1;
              const newAcc = [
                ...acc,
                [
                  {
                    order: nextOrder,
                    type_answers_id: curr.type_answers_id,
                    question_id: curr.question_id,
                    created_by: curr.created_by,
                    updated_by: curr.updated_by,
                    created_at: curr.created_at,
                    updated_at: curr.updated_at,
                  },
                ],
              ];
              return newAcc;
            }
            const newCond = {
              order: curr.order,
              type_answers_id: curr.type_answers_id,
              question_id: curr.question_id,
              created_by: curr.created_by,
              updated_by: curr.updated_by,
              created_at: curr.created_at,
              updated_at: curr.updated_at,
            };
            const combinedConditionInAcc = acc.find(
              (entry) => entry[0].order === curr.order,
            );
            if (combinedConditionInAcc) {
              const filteredAcc = acc.filter(
                (entry) => entry[0].order !== curr.order,
              );
              return [...filteredAcc, [...combinedConditionInAcc, newCond]];
            }
            return [...acc, [newCond]];
          },
          [],
        );
        return {
          label: question.name,
          id: question.question_id,
          type: typeAnswersData[0].type,
          order: question.order,
          description: question.description,
          value: orderedTypeAnswers,
          isDropdown: question.is_dropdown,
          isRandomOrderAnswer: question.is_random_order_answer,
          renderConditions: formattedConditions,
        };
      }),
    );
  }

  static async formatSteps(steps: StepModel[]) {
    return Promise.all(
      steps.map(async (step) => {
        const questionsData = await database
          .get<QuestionModel>('questions')
          .query(Q.where('step_id', step.step_id));
        const formattedQuestions = (
          await this.formatQuestions(questionsData)
        ).sort((a, b) => a.order - b.order);
        return {
          name: step.name,
          id: step.id,
          order: step.order,
          isQuota: step.is_quota,
          totalQuestions: step.total_questions,
          questions: formattedQuestions,
        };
      }),
    );
  }

  static async getFormData(id: number, formVersion: string) {
    const formData = await database
      .get<FormModel>('forms')
      .query(
        Q.and(Q.where('form_id', id), Q.where('form_version', formVersion)),
      );
    const steps = await database
      .get<StepModel>('steps')
      .query(
        Q.and(Q.where('form_id', id), Q.where('form_version', formVersion)),
      );

    let sessionType = '';
    if (formData[0]?.sesion_id) {
      const sessionData = await database
        .get<OperationalSessionModel>('operational_sessions')
        .query(Q.where('id', formData[0].sesion_id));
      sessionType = sessionData[0]?.type || '';
    }
    const stepsWithQuestions = (await this.formatSteps(steps)).sort(
      (a, b) => a.order - b.order,
    );
    const filteredSteps = stepsWithQuestions.filter(
      (step) => step.questions.length > 0,
    );
    return {
      formData: {
        name: formData[0].name,
        formId: formData[0].form_id,
        formVersion: formData[0].form_version,
        sesionId: formData[0].sesion_id,
        steps: filteredSteps,
        interviewId: '',
      },
      sessionType,
    };
  }

  // Save Interview
  static async createInterview(formData: IFormData) {
    const userCollection = database.get<UserModel>('users');
    const response = await userCollection.query().fetch();
    const userId = response[0].user_id;

    return database.write(async () => {
      const interviewData = await database
        .get<InterviewModel>('interviews')
        .create((interview) => {
          interview.form_id = formData.formId;
          interview.form_version = formData.formVersion;
          interview.session_id = formData.sesionId;
          interview.is_active = true;
          interview.is_discarted = true;
          interview.created_by = userId;
          interview.updated_by = userId;
          interview.created_at = new Date().getTime();
          interview.updated_at = new Date().getTime();
        });
      return interviewData;
    });
  }

  // Save answer
  static async createAnswer(
    interviewId: string,
    values: string[],
    question_id: number,
    text: string,
    is_quota: boolean,
    formId: number,
    formVersion: string,
    sesionId: string,
  ) {
    const userCollection = database.get<UserModel>('users');
    const response = await userCollection.query().fetch();
    const userId = response[0].user_id;

    return database.write(async () => {
      return Promise.all(
        values.map(async (value) => {
          await database
            .get<AnswerModel>('answers')
            .query(
              Q.and(
                Q.where('interview_id', interviewId),
                Q.where('question_id', question_id),
              ),
            )
            .destroyAllPermanently();
          return database.get<AnswerModel>('answers').create((newAnswer) => {
            newAnswer.interview_id = interviewId;
            newAnswer.type_answer_id = Number(value);
            newAnswer.question_id = question_id;
            newAnswer.answer_text = text || '';
            newAnswer.is_quota = is_quota;
            newAnswer.created_at = new Date().getTime();
            newAnswer.created_by = userId;
            newAnswer.updated_by = userId;
            newAnswer.updated_at = new Date().getTime();
            newAnswer.is_discarted = true;
            newAnswer.form_id = formId;
            newAnswer.form_version = formVersion;
            newAnswer.session_id = sesionId;
          });
        }),
      );
    });
  }

  // delete answers
  static async deleteAnswers(questionsToDelete: number[], interviewId: string) {
    return database.write(async () => {
      return Promise.all(
        questionsToDelete.map(async (question) => {
          return database
            .get<AnswerModel>('answers')
            .query(
              Q.and(
                Q.where('interview_id', interviewId),
                Q.where('question_id', question),
              ),
            )
            .destroyAllPermanently();
        }),
      );
    });
  }

  static async saveFormAnswers(
    formData: IFormData,
    formAnswers: IQuestionsToSave[],
    sampleToUpdate?: string,
  ) {
    const userCollection = database.get<UserModel>('users');
    const response = await userCollection.query().fetch();
    const userId = response[0].user_id;
    let interviewId = '';
    await database.write(async () => {
      const interviewData = await database
        .get<InterviewModel>('interviews')
        .create((interview) => {
          interview.form_id = formData.formId;
          interview.form_version = formData.formVersion;
          interview.session_id = formData.sesionId;
          interview.is_active = true;
          interview.is_discarted = !sampleToUpdate;
          interview.sample_id = Number(sampleToUpdate);
          interview.created_by = userId;
          interview.updated_by = userId;
          interview.created_at = formData.createdAt;
          interview.updated_at = new Date().getTime();
        });
      interviewId = interviewData.id;
      await Promise.all(
        formAnswers.map(async (answer) => {
          return Promise.all(
            answer.values.map(async (value) => {
              return database
                .get<AnswerModel>('answers')
                .create((newAnswer) => {
                  newAnswer.interview_id = interviewData.id;
                  newAnswer.type_answer_id = Number(value);
                  newAnswer.question_id = answer.question_id;
                  newAnswer.answer_text = answer.text || '';
                  newAnswer.form_id = formData.formId;
                  newAnswer.form_version = formData.formVersion;
                  newAnswer.session_id = formData.sesionId;
                  newAnswer.is_quota = answer.is_quota;
                  newAnswer.is_discarted = !sampleToUpdate;
                  newAnswer.created_at = new Date().getTime();
                  newAnswer.created_by = userId;
                  newAnswer.updated_by = userId;
                  newAnswer.updated_at = new Date().getTime();
                });
            }),
          );
        }),
      );
      if (sampleToUpdate && formData) {
        const sample = await database
          .get<SampleModel>('samples')
          .find(sampleToUpdate);
        await sample.update(() => {
          sample.total_answer += 1;
          sample.updated_by = userId;
          sample.updated_at = new Date().getDate();
        });
      }
      return null;
    });
    return interviewId;
  }

  static async getSamples(id: number, formVersion: string) {
    const quota = await database
      .get<QuotaModel>('quotas')
      .query(
        Q.and(Q.where('form_id', id), Q.where('form_version', formVersion)),
      );
    
    if(!quota[0]) {
      return { type: 'quota' }
    }

    const samples = await database
      .get<SampleModel>('samples')
      .query(Q.where('quotas_id', quota[0].quota_id));

      if(!samples[0]) {
        return { type: 'sample' }
      }

    const formatedSamplesToAnswer = (await Promise.all(
      samples.map(async (sample) => {
        const samplesToAnswer = await database
          .get<SampleToAnswerModel>('samples_to_answers')
          .query(Q.where('sample_id', sample.sample_id));
        return {
          sampleId: sample.sample_id,
          sampleValue: sample.value,
          sampleTotalAnswer: sample.total_answer,
          sampleToAnswer: samplesToAnswer,
        };
      }),
    )) as ISample[];
    return formatedSamplesToAnswer;
  }
  // static async getSessionType (formId:number, formVersion: string) {
  //   const
  // }
}
