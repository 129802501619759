import React, { useContext, useMemo, useState } from 'react';
import { Pressable, Text, View } from 'react-native';

import { PhoneInput } from '@/components/PhoneInput';
import { RadioButton } from '@/components/RadioButton';
import { TextInput } from '@/components/TextInput';
import { InterviewContext } from '@/contexts/InterviewContext';
import type { TypeAnswerModel } from '@/database/model/TypeAnswer';
import { GeneralFunctions } from '@/helpers/GeneralFunctions';

import { styles } from '../styles';
import { CheckboxGroup } from './CheckboxGroup';
import { DropdownComponent } from './Dropdown';

interface CardProps {
  label: string;
  type: string;
  value: TypeAnswerModel[];
  onChange: (value: TypeAnswerModel, text?: string) => void;
  findText: (id: string) => string;
  stepOrder: number;
  isDropdown: boolean;
  isRandomOrderAnswer: boolean;
}

export function Card({
  label,
  type,
  value,
  onChange,
  findText,
  stepOrder,
  isDropdown,
  isRandomOrderAnswer,
}: CardProps) {
  const { currentQuestionStep } = useContext(InterviewContext);

  const [showDropdown, setShowDropDown] = useState(false);

  const changeShowDropdown = () => {
    setShowDropDown((prevState) => !prevState);
  };

  const typeAnswers = useMemo(() => {
    if (['Escolha única', 'Escolha múltipla'].includes(type)) {
      const pinAnswers = value.filter((item) => item.pin_answer);
      const pinNotAnswers = value.filter((item) => !item.pin_answer);
      const randomOrderAnswers = GeneralFunctions.fisherYatesShuffle({
        array: pinNotAnswers,
      });
      pinAnswers.forEach((item) => {
        randomOrderAnswers.splice(item.order - 1, 0, item);
      });
      return isRandomOrderAnswer ? randomOrderAnswers : value;
    }
    return [];
  }, [isRandomOrderAnswer, type, value]);

  let cardContent;
  if (['Texto curto', 'Texto longo', 'Número'].includes(type)) {
    cardContent = (
      <TextInput
        placeholder={type.includes('Texto') ? 'texto' : 'número'}
        value={findText(value[0].id)}
        onChangeText={(newText) => {
          if (type === 'Número') {
            onChange(value[0], GeneralFunctions.numberMask(newText));
          } else {
            onChange(value[0], newText);
          }
        }}
        keyboardType={type === 'Número' ? 'number-pad' : 'default'}
        multiline={type === 'Texto longo'}
        numberOfLines={type === 'Texto longo' ? 3 : 1}
      />
    );
  } else if (type === 'Escolha única') {
    cardContent = isDropdown ? (
      <DropdownComponent
        answers={typeAnswers}
        isMultiple={false}
        onChange={onChange}
        showDropdown={showDropdown}
        changeShowDropdown={changeShowDropdown}
      />
    ) : (
      <RadioButton
        values={typeAnswers}
        onChange={(selectedValue) => onChange(selectedValue)}
      />
    );
  } else if (type === 'Escolha múltipla') {
    cardContent = isDropdown ? (
      <View style={{ position: 'relative' }}>
        <Pressable
          onPress={changeShowDropdown}
          style={{
            zIndex: 10,
            width: '100%',
            height: '80%',
            position: 'absolute',
          }}
        />
        <DropdownComponent
          answers={typeAnswers}
          isMultiple
          onChange={onChange}
          showDropdown={showDropdown}
          changeShowDropdown={changeShowDropdown}
        />
      </View>
    ) : (
      <CheckboxGroup
        values={typeAnswers}
        onChange={(selectedValue) => onChange(selectedValue)}
      />
    );
  } else {
    cardContent = (
      <PhoneInput
        value={findText(value[0].id)}
        onChangeText={(newText) => onChange(value[0], newText)}
      />
    );
  }

  return (
    <View style={styles.container}>
      <View style={styles.titleContainer}>
        <View style={styles.numberContainer}>
          <Text style={styles.number}>
            Pergunta {`${stepOrder}.${currentQuestionStep}`} - {type}
          </Text>
        </View>
      </View>
      <View style={styles.questionsContainer}>
        <Text style={styles.question}>{label}</Text>
        <View style={styles.optionsContainer}>{cardContent}</View>
      </View>
    </View>
  );
}
