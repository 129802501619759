/* eslint-disable no-console */
/* eslint-disable no-param-reassign */
import { API_BASE_URL } from '@env';
import { synchronize } from '@nozbe/watermelondb/sync';
import axios from 'axios';
import axiosRetry from 'axios-retry';
import * as Sentry from 'sentry-expo';

import { database } from '@/database';
import type { AnswerModel } from '@/database/model/Answer';
import type { InterviewModel } from '@/database/model/Interview';
import type { SampleModel } from '@/database/model/Sample';
import type { UserModel } from '@/database/model/User';

import { version as APP_VERSION } from '../../package.json';

axiosRetry(axios, {
  retries: 3,
  retryDelay: axiosRetry.exponentialDelay,
  onRetry: (retryCount, error, requestConfig) => {
    const message = `Retry attempt: ${retryCount} for ${requestConfig.url}`;
    console.log(message); // Logging to the console
    console.log(`Error: ${error.message}`);
    // Sending the retry attempt information to Sentry
    Sentry.Browser.captureMessage(
      `Retry attempt: ${retryCount}, Error: ${error.message}, URL: ${requestConfig.url}`,
    );
  },
});

const axiosInstance = axios.create({ baseURL: API_BASE_URL });

interface IInterview {
  id: string;
  interview_id: number;
  interview_version: string;
  session_id: string | null;
  name: string;
  form_id: number;
  form_version: string;
  is_active: boolean;
  created_by: number;
  updated_by: number;
  created_at: Date | null;
  updated_at: Date | null;
}

interface IAnswers {
  id: string;
  interview_id: string;
  interview_version: string;
  type_answer_id: number;
  question_id: number;
  answer_text: string | null;
  is_quota: boolean | null;
  created_by: number;
  updated_by: number;
  created_at: Date | null;
  updated_at: Date | null;
}

interface ISamples {
  sample_id: number;
  quotas_id: number;
  value: number;
  total_answer: number;
  order: number;
  created_by: number;
  updated_by: number;
  created_at: number;
  updated_at: number;
}

function logErrorToSentry(
  userId: string,
  action: string,
  error: Error,
  requestBody: any = null,
): void {
  const errorMessage = {
    message: `Sync error ${action}`,
    errorDetails: error.message,
    stack: error.stack,
    errorName: error.name,
    error: error.toString(),
    userId,
    appVersion: APP_VERSION,
    requestBody: requestBody ? JSON.stringify(requestBody) : 'No request body',
  };

  Sentry.Browser.setUser({ id: userId });
  Sentry.Browser.setContext('app', { version: APP_VERSION });
  Sentry.Browser.captureException(new Error(JSON.stringify(errorMessage)));
}

async function saveRemoteData(
  interviews: IInterview[],
  answers: IAnswers[],
  samples: ISamples[],
) {
  await Promise.all(
    interviews.map(async (interview) => {
      await database.write(async () => {
        const interviewData = await database
          .get<InterviewModel>('interviews')
          .find(interview.id);
        await interviewData.update(() => {
          interviewData.interview_id = interview.interview_id;
          interviewData.interview_version = interview.interview_version;
        });
      });
    }),
  );
  await Promise.all(
    answers.map(async (answer) => {
      await database.write(async () => {
        const answerData = await database
          .get<AnswerModel>('answers')
          .find(answer.id);
        await answerData.update(() => {
          answerData.interview_id = answer.interview_id;
          answerData.interview_version = answer.interview_version;
        });
      });
    }),
  );
  await Promise.all(
    samples.map(async (sample) => {
      await database.write(async () => {
        const sampleData = await database
          .get<SampleModel>('samples')
          .find(sample.sample_id.toString());
        await sampleData.update(() => {
          sampleData.total_answer = sample.total_answer;
          sampleData.value = sample.value;
        });
      });
    }),
  );
}

export async function syncAnswers() {
  const userCollection = database.get<UserModel>('users');
  const userData = await userCollection.query().fetch();
  const userId = userData[0].user_id;

  await synchronize({
    database,
    pullChanges: async ({ lastPulledAt, schemaVersion, migration }) => {
      const urlParams = `last_pulled_at=${lastPulledAt}&schema_version=${schemaVersion}&migration=${encodeURIComponent(
        JSON.stringify(migration),
      )}&clerkId=${userId}`;
      try {
        const response = await axiosInstance.get(`/sync?${urlParams}`);
        const { changes, timestamp } = response.data;
        return { changes, timestamp };
      } catch (error) {
        logErrorToSentry(userId, 'Pull', error as Error, null);
        throw error;
      }
    },
    pushChanges: async ({ changes, lastPulledAt }) => {
      try {
        const response = await axiosInstance.post(
          `/sync?last_pulled_at=${lastPulledAt}&clerkId=${userId}`,
          JSON.stringify(changes),
        );
        const { interviews, answers, samples } = response.data;
        if (interviews.length > 0 && answers.length > 0) {
          await saveRemoteData(interviews, answers, samples);
        }
      } catch (error) {
        logErrorToSentry(userId, 'Push', error as Error, changes);
        throw error;
      }
    },
    migrationsEnabledAtVersion: 1,
  });
}
