import { Model } from '@nozbe/watermelondb';
import { field } from '@nozbe/watermelondb/decorators';

export class StepModel extends Model {
  static table = 'steps';

  @field('step_id')
  step_id!: number;

  @field('name')
  name!: string;

  @field('order')
  order!: number;

  @field('is_quota')
  is_quota!: boolean;

  @field('form_id')
  form_id!: number;

  @field('form_version')
  form_version!: string;

  @field('total_questions')
  total_questions!: number;

  @field('created_by')
  created_by!: string;

  @field('updated_by')
  updated_by!: string;

  @field('created_at')
  created_at!: number;

  @field('updated_at')
  updated_at!: number;
}
