import React from 'react';
import { Text } from 'react-native';
import { TextInput as RNPTextInput } from 'react-native-paper';

import { Color, FontFamily } from '@/styles/GlobalStyles';

import { styles } from './styles';

type IPhoneInputProps = {
  value: string;
  onChangeText: (text: string) => void;
};

export function PhoneInput({ value, onChangeText }: IPhoneInputProps) {
  const phoneValidation = (text: string) => {
    if (text) {
      let test = text.replace(/\D/g, '');
      test = test.replace(/(\d{2})(\d)/, '($1) $2');
      test = test.replace(/(\d)(\d{4})$/, '$1-$2');
      return onChangeText(test);
    }
    return onChangeText('');
  };
  return (
    <>
      <Text style={styles.infoText}>Digite apenas os números.</Text>
      <RNPTextInput
        mode="outlined"
        placeholder="(99) 99999-9999"
        activeOutlineColor={Color.primary[300]}
        placeholderTextColor={Color.gray[200]}
        outlineColor={Color.gray[100]}
        value={value}
        maxLength={15}
        keyboardType="number-pad"
        onChangeText={(text) => phoneValidation(text)}
        theme={{
          fonts: {
            regular: { fontFamily: FontFamily.regular },
          },
          colors: { text: Color.gray[400] },
        }}
      />
    </>
  );
}
