/* eslint-disable no-param-reassign */
import { Audio } from 'expo-av';
import { openDB } from 'idb';
import type { ReactNode } from 'react';
import { createContext, useContext, useState } from 'react';

import { database } from '@/database';
import type { AudioModel } from '@/database/model/Audio';
import type { UserModel } from '@/database/model/User';

interface IContext {
  children: ReactNode;
}

interface IAudioContext {
  startRecording: (setTimer: (time: number) => void) => void;
  stopAudioRecording: (interviewId: string) => void;
}

export const AudioContext = createContext({} as IAudioContext);

export default function AudioContextProvider({ children }: IContext) {
  const [recordingAudio, setRecordingAudio] = useState<any>();
  const [permissionResponse, requestPermission] = Audio.usePermissions();
  const startRecording = async (setTimer: (time: number) => void) => {
    try {
      if (permissionResponse && permissionResponse.status !== 'granted') {
        console.log('Requesting permission..');
        await requestPermission();
      }
      await Audio.setAudioModeAsync({
        allowsRecordingIOS: true,
        playsInSilentModeIOS: true,
      });

      console.log('Starting recording..');
      const { recording } = await Audio.Recording.createAsync(
        Audio.RecordingOptionsPresets.HIGH_QUALITY,
      );
      setRecordingAudio(recording);
      recording.setOnRecordingStatusUpdate((status) => {
        setTimer(Math.floor(status.durationMillis / 1000));
      });
      console.log('Recording started');
    } catch (err) {
      console.error('Failed to start recording', err);
    }
  };

  const initDB = async () => {
    const db = await openDB('AudioDB', 1, {
      upgrade(dbUpgrade) {
        if (!dbUpgrade.objectStoreNames.contains('recordings')) {
          dbUpgrade.createObjectStore('recordings', {
            keyPath: 'id',
            autoIncrement: true,
          });
        }
      },
    });
    return db;
  };

  const blobToBase64 = (audioBlob: any) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(audioBlob);
      reader.onloadend = () => {
        const base64String = (reader.result as string).split(',')[1];
        resolve(base64String);
      };
      reader.onerror = reject;
    });
  };

  const stopAudioRecording = async (interviewId: string) => {
    console.log('Stopping recording..');
    if (recordingAudio) {
      setRecordingAudio(undefined);
      await recordingAudio.stopAndUnloadAsync();
      await Audio.setAudioModeAsync({
        allowsRecordingIOS: false,
      });
      const blobUri = recordingAudio.getURI();
      const response = await fetch(blobUri);
      const blob = await response.blob();
      // Save blob to IndexedDB
      const db = await initDB();
      const tx = db.transaction('recordings', 'readwrite');
      const store = tx.objectStore('recordings');
      const id = await store.put({ audio: blob, interviewId });
      await tx.done;
      console.log('Audio saved to IndexedDB');
      // Save audio in watermelon
      const savedBlob = await db.get('recordings', id);
      const userCollection = database.get<UserModel>('users');
      const userResponse = await userCollection.query().fetch();
      const userId = userResponse[0].user_id;
      const audioString = (await blobToBase64(savedBlob.audio)) as string;
      await database.write(async () => {
        await database.get<AudioModel>('audios').create((audio) => {
          audio.audio = audioString;
          audio.interview_id = interviewId;
          audio.created_by = userId;
          audio.updated_by = userId;
          audio.created_at = new Date().getTime();
          audio.updated_at = new Date().getTime();
        });
      });
    }
    return null;
  };

  // eslint-disable-next-line react/jsx-no-constructed-context-values
  const value = {
    startRecording,
    stopAudioRecording,
  };

  return (
    // eslint-disable-next-line react/react-in-jsx-scope
    <AudioContext.Provider value={value}>{children}</AudioContext.Provider>
  );
}

export const useAudioContext = () => useContext(AudioContext);
