type IShuffleObjectArray<ObjectType> = {
  array: ObjectType[];
};

export class GeneralFunctions {
  static roundedNumber(number: number, decimals: number) {
    const aux = 10 ** decimals;
    return Math.floor(number * aux) / aux;
  }

  static fisherYatesShuffle<ObjectType>({
    array,
  }: IShuffleObjectArray<ObjectType>) {
    const shuffledArray = [...array];

    for (let index = shuffledArray.length - 1; index > 0; index -= 1) {
      const randomIndex = Math.floor(Math.random() * (index + 1));
      // eslint-disable-next-line no-param-reassign
      [shuffledArray[index], shuffledArray[randomIndex]] = [
        shuffledArray[randomIndex],
        shuffledArray[index],
      ]; // swap
    }
    return shuffledArray;
  }
  
  static numberMask(text: string) {
    return text.replace(/\D/g, '');
  }
}
