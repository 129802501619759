import { Model } from '@nozbe/watermelondb';
import { field } from '@nozbe/watermelondb/decorators';

export class TypeAnswerModel extends Model {
  static table = 'type_answers';

  @field('type_answers_id')
  type_answer_id!: number;

  @field('value')
  value!: string;

  @field('question_id')
  question_id!: number;

  @field('order')
  order!: number;

  @field('type')
  type!: string;

  @field('skip_to_question')
  skip_to_question!: number;

  @field('is_unique')
  is_unique!: boolean;

  @field('pin_answer')
  pin_answer!: boolean;

  @field('created_by')
  created_by!: string;

  @field('updated_by')
  updated_by!: string;

  @field('created_at')
  created_at!: number;

  @field('updated_at')
  updated_at!: number;
}
