import { AnswerModel } from './Answer';
import { AudioModel } from './Audio';
import { ConditionalModel } from './Conditional';
import { FormModel } from './Form';
import { InterviewModel } from './Interview';
import { OperationalSessionModel } from './OperationalSession';
import { ProjectModel } from './Project';
import { QuestionModel } from './Question';
import { QuotaModel } from './Quota';
import { SampleModel } from './Sample';
import { SampleToAnswerModel } from './SampleToAnswer';
import { StepModel } from './Step';
import { TypeAnswerModel } from './TypeAnswer';
import { UserModel } from './User';

export const models = [
  FormModel,
  OperationalSessionModel,
  UserModel,
  StepModel,
  QuestionModel,
  TypeAnswerModel,
  ConditionalModel,
  ProjectModel,
  QuotaModel,
  SampleModel,
  SampleToAnswerModel,
  InterviewModel,
  AnswerModel,
  AudioModel,
];
