import { Settings } from 'lucide-react-native';
import React from 'react';
import type { StyleProp, ViewStyle } from 'react-native';
import { Pressable, Text } from 'react-native';

import { styles } from './styles';

type ProfileItemType = {
  style?: StyleProp<ViewStyle>;
  isSelected?: boolean;
};

export function ProfileItem({ style, isSelected }: ProfileItemType) {
  const iconColor = isSelected ? styles.selectedIcon : styles.notSelectedIcon;

  return (
    <Pressable style={[styles.buttonContainer, style]}>
      <Settings style={{ ...styles.icon, ...iconColor }} />
      <Text style={[styles.buttonText, style]}>Perfil</Text>
    </Pressable>
  );
}
