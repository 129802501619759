import { CheckCircle2, XCircle } from 'lucide-react-native';
import React, { memo } from 'react';
import { Text, View } from 'react-native';

import { Color } from '@/styles/GlobalStyles';

import { styles } from './styles';

type IRowProps = {
  id: number;
  form: string;
  startDate: string;
  duration: string;
  status: 'Descartada' | 'Concluído';
};

function RowComponent({ id, form, startDate, duration, status }: IRowProps) {
  const statusIcon = () => {
    switch (status) {
      case 'Descartada':
        return <XCircle style={styles.icon} fill={Color.secondary.red} />;
      default:
        return (
          <CheckCircle2 style={styles.icon} fill={Color.secondary.green} />
        );
    }
  };

  return (
    <View style={styles.rows}>
      <View style={[styles.row]}>
        <Text style={[styles.text]}>{id}</Text>
      </View>
      <View style={[styles.row]}>
        <Text style={[styles.text]} numberOfLines={2} ellipsizeMode="tail">
          {form}
        </Text>
      </View>
      <View style={[styles.row]}>
        <Text style={[styles.text]}>{startDate}</Text>
      </View>
      <View style={[styles.row]}>
        <Text style={[styles.text]}>{duration}</Text>
      </View>
      <View style={[styles.row, styles.status]}>
        {statusIcon()}
        <Text style={[styles.text]}>{status}</Text>
      </View>
    </View>
  );
}

export const Row = memo(RowComponent);
