import { StyleSheet } from 'react-native';

import {
  Border,
  Color,
  FontFamily,
  FontSize,
  Padding,
  Stroke,
} from '@/styles/GlobalStyles';

export const styles = StyleSheet.create({
  buttonContainer: {
    alignSelf: 'stretch',
    borderRadius: Border.lg,
    backgroundColor: Color.whiteSmoke_100,
    flexDirection: 'row',
    paddingVertical: Padding.p_5xs,
    paddingLeft: Padding.p_xs,
    paddingRight: Padding.p_base,
    alignItems: 'center',
    gap: 12,
  },
  icon: {
    height: 20,
    width: 20,
    strokeWidth: Stroke['1.5'],
    justifyContent: 'center',
    alignItems: 'center',
  },
  selectedIcon: {
    color: Color.darkSlateGray_100,
  },
  notSelectedIcon: {
    color: Color.dimGray,
  },
  buttonText: {
    fontSize: FontSize.base,
    lineHeight: 24,
    fontFamily: FontFamily.bold,
    color: Color.darkSlateGray_100,
  },
  centeredView: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
    marginTop: 22,
  },
  modalView: {
    margin: 20,
    backgroundColor: 'white',
    borderRadius: 20,
    padding: 35,
    alignItems: 'center',
    shadowColor: '#000',
    shadowOffset: {
      width: 0,
      height: 2,
    },
    shadowOpacity: 0.25,
    shadowRadius: 4,
    elevation: 5,
  },
  button: {
    borderRadius: 20,
    padding: 10,
    elevation: 2,
  },
  buttonClose: {
    backgroundColor: '#2196F3',
  },
  textStyle: {
    color: 'white',
    fontWeight: 'bold',
    textAlign: 'center',
  },
  modalText: {
    marginBottom: 15,
    textAlign: 'center',
  },
});
