import { createStackNavigator } from '@react-navigation/stack';
import React from 'react';

import { useAuth } from '@/hooks/auth';
import { SplashScreen } from '@/screens/SplashScreen';

import { AppRoutes } from './app.routes';
import { AuthRoutes } from './auth.routes';

const { Navigator, Screen } = createStackNavigator();

export function SplashRoutes() {
  const { user } = useAuth();

  return (
    <Navigator screenOptions={{ headerShown: false }} initialRouteName="splash">
      <Screen
        name="splash"
        component={SplashScreen}
        options={{ headerShown: false }}
      />

      <Screen
        name="root"
        component={user.id ? AppRoutes : AuthRoutes}
        options={{ headerShown: false }}
      />
    </Navigator>
  );
}
