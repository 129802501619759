import { tableSchema } from '@nozbe/watermelondb';

import type { ITable } from './interface';

export const sampleTable = {
  name: 'samples',
  columns: [
    {
      name: 'sample_id',
      type: 'number',
    },
    {
      name: 'quotas_id',
      type: 'number',
    },
    {
      name: 'value',
      type: 'number',
    },
    {
      name: 'total_answer',
      type: 'number',
    },
    {
      name: 'order',
      type: 'number',
      isOptional: true,
    },
    {
      name: 'created_by',
      type: 'string',
    },
    {
      name: 'updated_by',
      type: 'string',
    },
    {
      name: 'created_at',
      type: 'number',
    },
    {
      name: 'updated_at',
      type: 'number',
    },
  ],
} as ITable;

export const sampleSchema = tableSchema(sampleTable);
