import { useIsFocused } from '@react-navigation/native';
import React, { useEffect } from 'react';
import { Text, useWindowDimensions } from 'react-native';
import { SceneMap, TabBar, TabView } from 'react-native-tab-view';

import { Loader } from '@/components/Loader';
import { Color } from '@/styles/GlobalStyles';

import { Pending } from './components/Pending';
import { Synchronized } from './components/Synchronized';
import { styles } from './styles';

const renderScene = SceneMap({
  pending: Pending,
  synchronized: Synchronized,
});

export function Tabs() {
  const layout = useWindowDimensions();

  const [index, setIndex] = React.useState(0);
  const [routes] = React.useState([
    { key: 'pending', title: 'Pendentes' },
    { key: 'synchronized', title: 'Sincronizadas' },
  ]);
  const isFocused = useIsFocused();

  useEffect(() => {
    setIndex(0);
  }, [isFocused]);

  return (
    <TabView
      lazy
      renderLazyPlaceholder={() => (
        <Loader marginTop={12} color={Color.primary[300]} size={32} />
      )}
      navigationState={{ index, routes }}
      renderScene={renderScene}
      onIndexChange={setIndex}
      initialLayout={{ width: layout.width, height: layout.height }}
      style={styles.container}
      renderTabBar={(props) => {
        return (
          <TabBar
            {...props}
            indicatorStyle={styles.tabBarIndicatorStyle}
            style={styles.tabBarContainer}
            renderLabel={({ route }) => (
              <Text style={styles.tabLabel}>{route.title}</Text>
            )}
          />
        );
      }}
    />
  );
}
