import { StyleSheet } from 'react-native';

import { Color } from '@/styles/GlobalStyles';

export const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: Color.gray[50],
    marginTop: 48,
    gap: 48,
    paddingHorizontal: '9%',
    paddingBottom: 48,
    // alignSelf: 'stretch',
  },
});
