import React from 'react';
import { ActivityIndicator, View } from 'react-native';

import { Color } from '@/styles/GlobalStyles';

type ILoaderProps = {
  marginTop?: number;
  color?: string;
  size?: number;
};

export function Loader({
  marginTop = 0,
  color = Color.gray[100],
  size = 20,
}: ILoaderProps) {
  return (
    <View style={{ marginTop }}>
      <ActivityIndicator color={color} size={size} />
    </View>
  );
}
