import {
  useIsFocused,
  useNavigation,
  useRoute,
} from '@react-navigation/native';
import React, { useContext, useEffect, useState } from 'react';
import { View } from 'react-native';
import { SafeAreaView } from 'react-native-safe-area-context';
// eslint-disable-next-line unused-imports/no-unused-imports
import * as Sentry from 'sentry-expo';

import { InterviewForm } from '@/components/InterviewForm';
import { InterviewHeader } from '@/components/InterviewHeader';
import { InterviewContext } from '@/contexts/InterviewContext';
import { FormService } from '@/services/FormService';

import { styles } from './styles';
import { ISample } from '@/interfaces/formInterfaces';

type RouteParams = {
  formId: number;
  formVersion: string;
};
export function Interview() {
  const {
    getQuestionsToRender,
    saveFormData,
    saveSamplesData,
    verifyFormQuestions,
    formData,
    questionsToRender,
    stepIndex,
    questionIndex,
  } = useContext(InterviewContext);

  const navigation = useNavigation();
  const route = useRoute();
  const isFocused = useIsFocused();
  const [firstRender, setFirstRender] = useState(false);
  const [sessionType, setSessionType] = useState('');

  useEffect(() => {
    if (isFocused) {
      const { formId, formVersion } = route.params as RouteParams;
      setFirstRender(true);
      FormService.getFormData(formId, formVersion).then((response) => {
        saveFormData({ ...response.formData, createdAt: new Date().getTime() });
        setSessionType(response.sessionType);
        const validatedForm = verifyFormQuestions(response.formData.steps);

        if (!validatedForm) {
          navigation.navigate('error', { type: 'answer' });
        }

        FormService.getSamples(formId, formVersion).then(
          (samplesResponse) => {
            if( 'type' in samplesResponse) {
              navigation.navigate('error', { type: samplesResponse.type });
            }
            saveSamplesData(samplesResponse as ISample[]);
            getQuestionsToRender();
          },
        );

      });
    } else {
      setFirstRender(false);
    }
  }, [isFocused]);

  useEffect(() => {
    if (firstRender) {
      getQuestionsToRender();
    }
  }, [firstRender, stepIndex, questionIndex]);

  return (
    <SafeAreaView style={styles.container}>
      <InterviewHeader name={formData?.name} />
      <View style={styles.formContainer}>
        {questionsToRender.length && (
          <InterviewForm
            dataToRender={questionsToRender}
            sessionType={sessionType}
          />
        )}
      </View>
    </SafeAreaView>
  );
}
