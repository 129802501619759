import { tableSchema } from '@nozbe/watermelondb';

import type { ITable } from './interface';

export const formTable = {
  name: 'forms',
  columns: [
    {
      name: 'form_id',
      type: 'number',
    },
    {
      name: 'form_version',
      type: 'string',
    },
    {
      name: 'name',
      type: 'string',
    },
    {
      name: 'sesion_id',
      type: 'string',
    },
    {
      name: 'is_template',
      type: 'boolean',
    },
    {
      name: 'archived',
      type: 'boolean',
    },
    {
      name: 'created_by',
      type: 'string',
    },
    {
      name: 'updated_by',
      type: 'string',
    },
    {
      name: 'archived_by',
      type: 'string',
      isOptional: true,
    },
    {
      name: 'created_at',
      type: 'number',
    },
    {
      name: 'updated_at',
      type: 'number',
    },
  ],
} as ITable;

export const formSchema = tableSchema(formTable);
