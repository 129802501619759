import { StyleSheet } from 'react-native';

import { Color, FontFamily, FontSize } from '@/styles/GlobalStyles';

export const styles = StyleSheet.create({
  centeredView: {
    alignItems: 'center',
    flex: 1,
    justifyContent: 'center',
  },
  modalView: {
    backgroundColor: 'white',
    borderRadius: 20,
    shadowColor: '#000',
    shadowOffset: {
      width: 0,
      height: 2,
    },
    shadowOpacity: 0.25,
    shadowRadius: 4,
    elevation: 5,
    width: '80%',
    maxWidth: 698,
    gap: 40,
    paddingVertical: 80,
    paddingHorizontal: 24,
    position: 'relative',
  },
  closeContainer: {
    position: 'absolute',
    right: 16,
    top: 16,
  },
  closeIcon: {
    height: 24,
    width: 24,
    color: Color.gray[400],
  },
  exclamationContainer: {
    marginHorizontal: 'auto',
  },
  exclamationIcon: {
    width: 104,
    height: 94,
  },
  modalText: {
    color: Color.gray[400],
    textAlign: 'center',
    fontFamily: FontFamily.semiBold,
    fontSize: 20,
    maxWidth: 300,
    marginHorizontal: 'auto',
  },
  separator: {
    width: '100%',
    gap: 24,
  },
  separatorColumn: {
    flexDirection: 'column-reverse',
  },
  separatorRow: {
    flexDirection: 'row', // Alinha os filhos em linha
    justifyContent: 'center',
  },
  button: {
    borderRadius: 8,
    paddingVertical: 12,
    paddingHorizontal: 24,
    elevation: 2,
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
  },
  buttonClose: {
    border: `1px solid ${Color.gray[400]}`,
    backgroundColor: 'transparent',
  },
  buttonDiscard: {
    backgroundColor: Color.reddishOrange,
    border: `1px solid ${Color.reddishOrange}`,
  },
  buttonMobile: {
    width: '100%',
  },
  textStyle: {
    fontFamily: FontFamily.medium,
    lineHeight: 24,
    fontSize: FontSize.base,
  },
  textClose: {
    color: Color.gray[400],
  },
  textDiscard: {
    color: 'white',
  },
});
