import { useClerk } from '@clerk/clerk-react';
import { zodResolver } from '@hookform/resolvers/zod';
import { useNetInfo } from '@react-native-community/netinfo';
import { useIsFocused } from '@react-navigation/native';
import { Image } from 'expo-image';
import { Eye, EyeOffIcon } from 'lucide-react-native';
import React, { useCallback, useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { Pressable, Text, TextInput, View } from 'react-native';
import { SafeAreaView } from 'react-native-safe-area-context';
// eslint-disable-next-line unused-imports/no-unused-imports
import * as Sentry from 'sentry-expo';
import * as zod from 'zod';

import logoFutura from '@/assets/logo-futura-azul-escuro12.png';
import { Loader } from '@/components/Loader';
import { useAuth } from '@/hooks/auth';
import { Color } from '@/styles/GlobalStyles';

import { styles } from './styles';

const LoginFormValidationSchema = zod.object({
  username: zod
    .string({
      required_error: 'O nome do usuário é obrigatório.',
    })
    .nonempty('Informe o nome do usuário.'),
  password: zod
    .string({ required_error: 'O campo senha é obrigatório.' })
    .nonempty('Informe a senha.'),
});

type ILoginFormData = zod.infer<typeof LoginFormValidationSchema>;

export function Login() {
  const { session, signOut } = useClerk();
  const netInfo = useNetInfo();
  const { login, loading } = useAuth();
  const isFocused = useIsFocused();

  const [isLoading, setIsLoading] = useState(loading);
  const [showPassword, setShowPassword] = useState(false);

  const handleClickShowPassword = useCallback(
    () => setShowPassword((show) => !show),
    [],
  );

  const {
    control,
    clearErrors,
    handleSubmit,
    formState: { errors },
    setError,
  } = useForm<ILoginFormData>({
    resolver: zodResolver(LoginFormValidationSchema),
    defaultValues: {
      username: '',
      password: '',
    },
  });

  const onSignInPress = async (data: ILoginFormData) => {
    clearErrors();

    if (!netInfo.isConnected) {
      return alert('Você precisa estar conectado para fazer o login.');
    }

    try {
      setIsLoading(true);
      return await login({ ...data });
    } catch (err: any) {
      setIsLoading(false);
      console.log(err);
      return setError('root', {
        message:
          'Confira seu usuário e senha. Se o erro persistir consulte o administrador.',
      });
    }
  };

  useEffect(() => {
    if (session && session.user.publicMetadata.role !== 'Entrevistador') {
      setError('root', {
        message: 'Esse usuário não tem permissão de acesso.',
      });
      signOut();
      setIsLoading(false);
    }
  }, [session, isFocused]);

  return (
    <SafeAreaView style={styles.container}>
      <View style={styles.loginContainer}>
        <Image style={styles.logo} contentFit="cover" source={logoFutura} />
        <View style={styles.formContainer}>
          <View style={styles.inputsContainer}>
            <View style={styles.inputContainer}>
              <Text style={styles.label}>Nome do usuário</Text>
              <Controller
                control={control}
                render={({ field: { onChange, onBlur, value } }) => (
                  <TextInput
                    style={[styles.input, styles.inputWithBorder]}
                    placeholder="futura"
                    placeholderTextColor={Color.gray[200]}
                    onChangeText={onChange}
                    onBlur={onBlur}
                    value={value}
                  />
                )}
                name="username"
              />
              {errors.username && (
                <Text style={styles.errorMessage}>
                  {errors.username.message}
                </Text>
              )}
            </View>

            <View style={styles.inputContainer}>
              <Text style={styles.label}>Senha</Text>
              <Controller
                control={control}
                render={({ field: { onChange, onBlur, value } }) => (
                  <View style={styles.inputContainerWithIcon}>
                    <TextInput
                      style={styles.input}
                      placeholder="Digite a senha"
                      secureTextEntry={!showPassword}
                      placeholderTextColor={Color.gray[200]}
                      onChange={onChange}
                      onBlur={onBlur}
                      value={value}
                    />
                    <Pressable onPress={handleClickShowPassword}>
                      {showPassword ? (
                        <EyeOffIcon style={styles.icon} />
                      ) : (
                        <Eye style={styles.icon} />
                      )}
                    </Pressable>
                  </View>
                )}
                name="password"
              />
              {errors.password && (
                <Text style={styles.errorMessage}>
                  {errors.password.message}
                </Text>
              )}
              {errors.root && (
                <Text style={styles.errorMessage}>{errors.root.message}</Text>
              )}
            </View>
          </View>

          <View style={styles.buttonsContainer}>
            <Pressable
              style={
                isLoading
                  ? [styles.loginButton, styles.loginButtonDisabled]
                  : styles.loginButton
              }
              onPress={handleSubmit(onSignInPress)}
              disabled={isLoading}
            >
              {isLoading ? (
                <Loader />
              ) : (
                <Text style={styles.loginButtonText}>Entrar</Text>
              )}
            </Pressable>
          </View>
        </View>
      </View>
    </SafeAreaView>
  );
}
