import { tableSchema } from '@nozbe/watermelondb';

import type { ITable } from './interface';

export const projectTable = {
  name: 'projects',
  columns: [
    {
      name: 'project_id',
      type: 'string',
    },
    {
      name: 'name',
      type: 'string',
    },
    {
      name: 'created_by',
      type: 'string',
      isOptional: true,
    },
    {
      name: 'updated_by',
      type: 'string',
      isOptional: true,
    },
    {
      name: 'created_at',
      type: 'number',
    },
    {
      name: 'updated_at',
      type: 'number',
    },
  ],
} as ITable;

export const projectSchema = tableSchema(projectTable);
