import { tableSchema } from '@nozbe/watermelondb';

import type { ITable } from './interface';

export const operationalSessionTable = {
  name: 'operational_sessions',
  columns: [
    {
      name: 'session_id',
      type: 'string',
    },
    {
      name: 'title',
      type: 'string',
    },
    {
      name: 'project_id',
      type: 'string',
      isOptional: true,
    },
    {
      name: 'type',
      type: 'string',
      isOptional: true,
    },
    {
      name: 'start_date',
      type: 'number',
      isOptional: true,
    },
    {
      name: 'end_date',
      type: 'number',
      isOptional: true,
    },
    {
      name: 'location',
      type: 'string',
    },
    {
      name: 'thematic',
      type: 'string',
    },
    {
      name: 'status',
      type: 'string',
    },
    {
      name: 'archived',
      type: 'boolean',
    },
    {
      name: 'created_by',
      type: 'string',
      isOptional: true,
    },
    {
      name: 'updated_by',
      type: 'string',
      isOptional: true,
    },
    {
      name: 'archived_by',
      type: 'string',
      isOptional: true,
    },
    {
      name: 'created_at',
      type: 'number',
    },
    {
      name: 'updated_at',
      type: 'number',
    },
  ],
} as ITable;

export const operationalSessionSchema = tableSchema(operationalSessionTable);
