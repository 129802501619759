import type { DrawerContentComponentProps } from '@react-navigation/drawer';
import { createDrawerNavigator } from '@react-navigation/drawer';
import React from 'react';

import { Menu } from '@/components/Menu';
import { Login } from '@/screens/Login';

const { Navigator, Screen } = createDrawerNavigator();

function CustomDrawerContent(props: DrawerContentComponentProps) {
  return <Menu {...props} />;
}

export function AuthRoutes() {
  return (
    <Navigator
      screenOptions={{ headerShown: false, drawerStyle: { width: 258 } }}
      initialRouteName="splash"
      drawerContent={CustomDrawerContent}
    >
      <Screen name="login" component={Login} options={{ headerShown: false }} />
    </Navigator>
  );
}
