import { useNavigation } from '@react-navigation/native';
import { Home } from 'lucide-react-native';
import React from 'react';
import type { StyleProp, ViewStyle } from 'react-native';
import { Pressable, Text } from 'react-native';

import { styles } from './styles';

type HomeItemType = {
  style?: StyleProp<ViewStyle>;
  isSelected?: boolean;
};

export function HomeItem({ style, isSelected }: HomeItemType) {
  const navigation = useNavigation();
  const iconColor = isSelected ? styles.selectedIcon : styles.notSelectedIcon;

  return (
    <Pressable
      style={[styles.buttonContainer, style]}
      onPress={() => navigation.navigate('home')}
    >
      <Home style={{ ...styles.icon, ...iconColor }} />
      <Text style={[styles.buttonText, style]}>Home</Text>
    </Pressable>
  );
}
