import { StyleSheet } from 'react-native';

import { Color, Padding } from '@/styles/GlobalStyles';

export const styles = StyleSheet.create({
  container: {
    backgroundColor: Color.gray.white,
    padding: Padding.p_xl,
    gap: 24,
  },
  imagesContainer: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  logoMenu: {
    width: 85,
    height: 24,
    zIndex: 0,
  },
  closeMenu: {
    width: 14,
    height: 14,
  },
  options: {
    // flex: 1,
    // width: 218,
    // zIndex: 1,
    // marginTop: 24,
  },
});
