import type { ReactNode } from 'react';
import { createContext, useCallback, useContext, useState } from 'react';

import { syncAnswers } from '@/services/Synchronize';

interface IContext {
  children: ReactNode;
}

interface ISyncContext {
  isSyncing: boolean;
  setSync: () => Promise<boolean>;
  isModalVisible: boolean;
  handleShowIsModalVisible: (_visible: boolean) => void;
  lastUpdate: Date | undefined;
  cancelIsSyncing: () => void;
}

export const SyncContext = createContext({} as ISyncContext);

export default function SyncContextProvider({ children }: IContext) {
  const [isSyncing, setIsSyncing] = useState(false);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [lastUpdate, setLastUpdate] = useState<Date>();

  const handleShowIsModalVisible = useCallback((visible: boolean) => {
    setIsModalVisible(visible);
  }, []);

  const cancelIsSyncing = () => {
    setIsSyncing(false);
  };

  const setSync = async () => {
    if (!isSyncing) {
      setIsSyncing(true);
      await syncAnswers();
      await syncAnswers();
      setIsSyncing(false);
      setLastUpdate(new Date());
      return true;
    }
    return false;
  };

  // eslint-disable-next-line react/jsx-no-constructed-context-values
  const value = {
    isSyncing,
    setSync,
    isModalVisible,
    handleShowIsModalVisible,
    lastUpdate,
    cancelIsSyncing,
  };

  return (
    // eslint-disable-next-line react/react-in-jsx-scope
    <SyncContext.Provider value={value}>{children}</SyncContext.Provider>
  );
}

export const useSyncContext = () => useContext(SyncContext);
