import React, { useContext, useEffect, useMemo, useState } from 'react';
import { Image, Pressable, Text, View } from 'react-native';
import Dropdown from 'react-native-input-select';

import { InterviewContext } from '@/contexts/InterviewContext';
import type { TypeAnswerModel } from '@/database/model/TypeAnswer';
import { Color } from '@/styles/GlobalStyles';

import { styles } from '../styles';

interface IProps {
  answers: TypeAnswerModel[];
  isMultiple: boolean;
  onChange: (value: TypeAnswerModel) => void;
  showDropdown: boolean;
  changeShowDropdown: () => void;
}
interface IListValues {
  id: string;
  value: string;
  question_id: number;
  is_unique: boolean;
}

export function DropdownComponent({
  answers,
  isMultiple,
  onChange,
  showDropdown,
  changeShowDropdown,
}: IProps) {
  const [listValues, setListValues] = useState<IListValues[]>([]);
  const [selectedValue, setSelectedValue] = useState<string | string[]>(
    isMultiple ? [] : '',
  );

  const { answeredQuestions, formData, stepIndex } =
    useContext(InterviewContext);

  useEffect(() => {
    const listValuesData = answers.map((answerData) => ({
      id: answerData.id,
      value: answerData.value,
      question_id: answerData.question_id,
      is_unique: answerData.is_unique,
    }));
    setListValues(listValuesData);

    const isAnswered = answeredQuestions.find(
      (answer) => answer.question_id === answers[0].question_id,
    );

    if (isAnswered && formData) {
      const currentQuestion = formData.steps[stepIndex].questions.find(
        (question) => question.id === answers[0].question_id,
      );
      if (isAnswered.type === 'Escolha múltipla') {
        const answersIds = isAnswered.values;
        const selected = answersIds.reduce((acc, curr) => {
          const find = currentQuestion?.value.find(
            (answer) => answer.id === curr,
          );

          if (find) {
            return [...acc, find.value];
          }

          return acc;
        }, [] as string[]);
        setSelectedValue(selected);
      } else {
        const answerId = isAnswered.values[0];
        const answerValue = currentQuestion?.value.find(
          (answer) => answer.id === answerId,
        );
        setSelectedValue(answerValue?.value || '');
      }
    }
  }, []);

  const saveMultipleAnswers = (answersValue: string[]) => {
    const newAnswerValue =
      selectedValue.length > 0
        ? answersValue.filter((value) => !selectedValue.includes(value))
        : answersValue;
    if (newAnswerValue.length !== 0) {
      return newAnswerValue.forEach((answerValue) => {
        const answerData = answers.find((value) => value.value === answerValue);
        if (answerData) onChange(answerData);
      });
    }
    const deselectedValue = (selectedValue as string[]).filter(
      (value) => !answersValue.includes(value),
    );
    return deselectedValue.forEach((answerValue) => {
      const answerData = answers.find((value) => value.value === answerValue);
      if (answerData) onChange(answerData);
    });
  };

  const getAnswerData = (answeredValue: string | string[]) => {
    if (typeof answeredValue === 'string') {
      const answerData = answers.find((value) => value.value === answeredValue);
      if (answerData) onChange(answerData);
    } else {
      saveMultipleAnswers(answeredValue);
    }
    setSelectedValue(answeredValue);
    if (!isMultiple) changeShowDropdown();
  };

  const answerBlocked = (item: IListValues) => {
    const selected = answeredQuestions
      .filter((answer) => answer.question_id === item.question_id)
      .some((answer) => {
        const select = answer.values.find((value) => value === item.id);
        const filtered = answers
          .filter((value) => answer.values.includes(value.id))
          .some((value) => value.is_unique);

        if (answer.values?.length === 0) {
          return false;
        }
        if (select) {
          return false;
        }

        if (!select && item.is_unique) {
          return true;
        }

        if (!select) {
          return filtered;
        }
        return false;
      });

    return selected;
  };

  const options = useMemo(() => {
    const newList = listValues.map((value) => ({
      ...value,
      disabled: answerBlocked(value),
    }));
    return newList;
  }, [listValues, answeredQuestions]);

  return isMultiple ? (
    <Dropdown
      label="Lista de Respostas"
      placeholder="Selecione uma opção"
      options={options}
      optionLabel="value"
      selectedValue={selectedValue}
      onValueChange={(itemValue: any) => getAnswerData(itemValue)}
      isMultiple={isMultiple}
      isSearchable
      hideModal={false}
      searchControls={{
        textInputContainerStyle: {
          marginHorizontal: 20,
          marginVertical: 10,
        },
        textInputProps: {
          placeholder: 'Digite aqui',
        },
      }}
      listHeaderComponent={
        <View>
          <View style={styles.closeDropdownButtonContainer}>
            <Pressable
              id="close-button"
              onPress={changeShowDropdown}
              style={styles.closeDropdownButton}
            >
              <Text style={styles.closeDropdownText}>Voltar para pergunta</Text>
            </Pressable>
          </View>
          <Text style={{ paddingHorizontal: 20 }}>Selecione a opção</Text>
        </View>
      }
      primaryColor={Color.primary[300]}
      dropdownStyle={{
        borderWidth: 1, // To remove border, set borderWidth to 0
      }}
      dropdownIcon={
        <Image
          source={{
            uri: 'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADMAAAAzCAYAAAA6oTAqAAAAEXRFWHRTb2Z0d2FyZQBwbmdjcnVzaEB1SfMAAABQSURBVGje7dSxCQBACARB+2/ab8BEeQNhFi6WSYzYLYudDQYGBgYGBgYGBgYGBgYGBgZmcvDqYGBgmhivGQYGBgYGBgYGBgYGBgYGBgbmQw+P/eMrC5UTVAAAAABJRU5ErkJggg==',
          }}
        />
      }
      dropdownIconStyle={{ top: 20, right: 20 }}
      modalOptionsContainerStyle={{
        paddingHorizontal: 10,
        paddingVertical: 16,
        height: '100%',
        overflow: 'scroll',
        borderRadius: 0,
      }}
      modalProps={{
        supportedOrientations: [
          'portrait',
          'portrait-upside-down',
          'landscape',
          'landscape-left',
          'landscape-right',
        ],
        visible: showDropdown,
        style: { zIndex: 20 },
        transparent: true,
      }}
      listComponentStyles={{
        listEmptyComponentStyle: {
          color: 'red',
        },
        itemSeparatorStyle: {
          opacity: 0,
          borderColor: 'white',
          borderWidth: 1,
        },
        sectionHeaderStyle: {
          padding: 10,
        },
      }}
      listControls={{
        hideSelectAll: true,
        emptyListMessage: 'Nenhum resultado foi encontrado',
      }}
    />
  ) : (
    <Dropdown
      label="Lista de Respostas"
      placeholder="Selecione uma opção"
      options={options}
      optionLabel="value"
      selectedValue={selectedValue}
      onValueChange={(itemValue: any) => getAnswerData(itemValue)}
      isMultiple={isMultiple}
      isSearchable
      hideModal={false}
      searchControls={{
        textInputProps: {
          placeholder: 'Digite aqui',
        },
      }}
      listHeaderComponent={
        <View>
          <Text style={{ paddingHorizontal: 20 }}>Selecione a opção</Text>
        </View>
      }
      checkboxComponent={
        !isMultiple && (
          <View
            style={{
              width: 20,
              height: 20,
              borderRadius: 20 / 2,
              borderWidth: 3,
              borderColor: 'white',
            }}
          />
        )
      }
      checkboxComponentStyles={
        !isMultiple
          ? {
              checkboxStyle: {
                backgroundColor: Color.primary[300],
                borderRadius: 30,
                borderColor: Color.primary[300],
              },
            }
          : {}
      }
      primaryColor={Color.primary[300]}
      dropdownStyle={{
        borderWidth: 1, // To remove border, set borderWidth to 0
      }}
      dropdownIcon={
        <Image
          source={{
            uri: 'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADMAAAAzCAYAAAA6oTAqAAAAEXRFWHRTb2Z0d2FyZQBwbmdjcnVzaEB1SfMAAABQSURBVGje7dSxCQBACARB+2/ab8BEeQNhFi6WSYzYLYudDQYGBgYGBgYGBgYGBgYGBgZmcvDqYGBgmhivGQYGBgYGBgYGBgYGBgYGBgbmQw+P/eMrC5UTVAAAAABJRU5ErkJggg==',
          }}
        />
      }
      dropdownIconStyle={{ top: 20, right: 20 }}
      modalOptionsContainerStyle={{
        padding: 10,
        height: '100%',
        overflow: 'scroll',
      }}
      modalProps={{
        supportedOrientations: [
          'portrait',
          'portrait-upside-down',
          'landscape',
          'landscape-left',
          'landscape-right',
        ],
        style: { zIndex: 20 },
        transparent: true,
      }}
      listComponentStyles={{
        listEmptyComponentStyle: {
          color: 'red',
        },
        itemSeparatorStyle: {
          opacity: 0,
          borderColor: 'white',
          borderWidth: 1,
        },
        sectionHeaderStyle: {
          padding: 10,
        },
      }}
      listControls={{
        hideSelectAll: true,
        emptyListMessage: 'Nenhum resultado foi encontrado',
      }}
    />
  );
}
