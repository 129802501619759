import { Model } from '@nozbe/watermelondb';
import { field } from '@nozbe/watermelondb/decorators';

export class InterviewModel extends Model {
  static table = 'interviews';

  @field('interview_id')
  interview_id!: number;

  @field('interview_version')
  interview_version!: string;

  @field('session_id')
  session_id!: string;

  @field('name')
  name!: string;

  @field('form_id')
  form_id!: number;

  @field('form_version')
  form_version!: string;

  @field('is_active')
  is_active!: boolean;

  @field('is_discarted')
  is_discarted!: boolean;

  @field('created_by')
  created_by!: string;

  @field('updated_by')
  updated_by!: string;

  @field('created_at')
  created_at!: number;

  @field('updated_at')
  updated_at!: number;

  @field('sample_id')
  sample_id!: number;
}
