import { StyleSheet } from 'react-native';

import { Color, FontFamily, FontSize } from '@/styles/GlobalStyles';

export const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: Color.gray[50],
    marginTop: 48,
    gap: 48,
    paddingHorizontal: '9%',
    paddingBottom: 32,
    overflowX: 'auto',
  },
  header: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  infoScreen: {
    alignItems: 'flex-start',
    flexDirection: 'column',
    justifyContent: 'center',
    gap: 4,
  },
  name: {
    color: Color.gray[200],
    fontFamily: FontFamily.regular,
    fontSize: FontSize.xs,
    lineHeight: 24,
    textTransform: 'uppercase',
  },
  title: {
    color: Color.darkSlateGray_100,
    fontFamily: FontFamily.semiBold,
    fontSize: FontSize.lg,
    lineHeight: 24,
  },
  dashboardContainer: {
    flex: 1,
    alignItems: 'flex-start',
    gap: 40,
    alignSelf: 'stretch',
    width: '100%',
  },
});
