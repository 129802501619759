import React, { useEffect, useState } from 'react';
import { Text, View } from 'react-native';

import { useAudioContext } from '@/contexts/AudioContext';

import { styles } from './styles';

export function AudioComponent() {
  const [audioDuration, setAudioDuration] = useState('00:00');
  const { startRecording } = useAudioContext();

  const setTimer = (time: number) => {
    const minutes = Math.floor(time / 60);
    const seconds = time % 60;
    setAudioDuration(`${minutes}:${seconds < 10 ? '0' : ''}${seconds}`);
  };

  useEffect(() => {
    startRecording(setTimer);
  }, []);
  return (
    <View style={styles.audioContainer}>
      <View style={styles.container}>
        <Text style={styles.text}>Gravação de entrevista</Text>
        <Text style={styles.text}>{audioDuration}</Text>
      </View>
    </View>
  );
}
