/* eslint-disable no-param-reassign */
import { Q } from '@nozbe/watermelondb';
import { useIsFocused } from '@react-navigation/native';
import React, { useEffect, useState } from 'react';
import { FlatList, Text, View } from 'react-native';
// eslint-disable-next-line unused-imports/no-unused-imports
import * as Sentry from 'sentry-expo';

import { Header } from '@/components/Header';
import { Loader } from '@/components/Loader';
import { SafeAreaView } from '@/components/SafeAreaView';
import { useSyncContext } from '@/contexts/syncContext';
import { database } from '@/database';
import type { FormModel } from '@/database/model/Form';
import type { OperationalSessionModel } from '@/database/model/OperationalSession';
import { useAuth } from '@/hooks/auth';
import type { IFormList } from '@/interfaces/formInterfaces';
import { Color } from '@/styles/GlobalStyles';

import packageJson from '../../../package.json';
import { FormRow } from './components/FormRow';
import { styles } from './styles';

export function Home() {
  const { user } = useAuth();
  const { setSync, cancelIsSyncing } = useSyncContext();
  const isFocused = useIsFocused();

  const [forms, setForms] = useState<IFormList[]>([]);
  const [loading, setIsLoading] = useState(false);
  const [isSyncing, setIsisSyncing] = useState(false);
  const [synced, setSynced] = useState(false);

  useEffect(() => {
    setIsisSyncing(true);
    Sentry.Browser.setUser({
      id: user.id,
      username: user.username || '',
    });
    setSync()
      .then(() => {
        setSynced((prev) => !prev);
        setIsisSyncing(false);
      })
      .catch(() => {
        setIsisSyncing(false);
        cancelIsSyncing();
      });
  }, []);

  useEffect(() => {
    setIsLoading(true);
    database
      .get<FormModel>('forms')
      .query()
      .fetch()
      .then(async (response) => {
        const formData = await Promise.all(
          response.map(async (form) => {
            const sessionData = await database
              .get<OperationalSessionModel>('operational_sessions')
              .query(Q.where('id', form.sesion_id));
            return {
              form_id: form.form_id,
              form_version: form.form_version,
              name: form.name,
              sessionName: sessionData[0]?.title as string,
            };
          }),
        );
        setForms(formData);
        setIsLoading(false);
      });
  }, [isFocused, synced]);

  return (
    <SafeAreaView>
      <Header />
      {loading || isSyncing ? (
        <Loader marginTop={32} color={Color.primary[300]} size={32} />
      ) : (
        <View style={styles.container}>
          <View style={styles.textContainer}>
            <Text style={styles.greetingUser}>Olá, {user.name}</Text>
            <Text style={styles.message}>
              Selecione o questionário para iniciar a entrevista.
            </Text>
          </View>

          <View style={styles.surveyContainer}>
            <Text style={styles.title}>Entrevistas da equipe</Text>
            <View style={styles.listQuestions}>
              {forms && forms.length ? (
                <>
                  <View style={[styles.listHeader, styles.row]}>
                    <Text style={[styles.listHeaderText, styles.listText]}>
                      Questionário
                    </Text>
                    <Text style={[styles.listHeaderText, styles.listText]}>
                      Sessão
                    </Text>
                  </View>
                  <FlatList
                    data={forms}
                    contentContainerStyle={{ gap: 14 }}
                    style={{ flex: 1, paddingBottom: 20 }}
                    renderItem={({ item }) => <FormRow formData={item} />}
                    showsVerticalScrollIndicator={false}
                    keyExtractor={(item) =>
                      `${item.form_id}-${item.form_version}`
                    }
                  />
                </>
              ) : (
                <Text style={styles.greetingUser}>
                  Nenhum questionário encontrado para sua equipe.
                </Text>
              )}
            </View>
            <Text style={styles.versionText}>Versão {packageJson.version}</Text>
          </View>
        </View>
      )}
    </SafeAreaView>
  );
}
