import { isSameDay, isToday } from 'date-fns';
import React, { memo, useMemo } from 'react';
import { Text, useWindowDimensions, View } from 'react-native';

import { GeneralFunctions } from '@/helpers/GeneralFunctions';
import type { ISyncList } from '@/libs/types/sync';

import { styles } from './styles';

type ICardProps = {
  interviews: ISyncList[];
};

function CardComponent({ interviews }: ICardProps) {
  const { width } = useWindowDimensions();
  const isMobile = width < 540;

  const completedInterviews = interviews.filter(
    (interview) =>
      isToday(new Date(interview.created_at)) && !interview.is_discarted,
  ).length;

  const averageInterviewsCompletedDay = useMemo(() => {
    let totalDays = 0;
    let actualDay = 0;
    if (interviews.length > 0) {
      interviews.forEach((interview) => {
        const sameDay = isSameDay(
          new Date(interview.created_at),
          new Date(actualDay),
        );
        if (!sameDay) {
          actualDay = interview.created_at;
          totalDays += 1;
        }
      });
      return GeneralFunctions.roundedNumber(interviews.length / totalDays, 2);
    }

    return 0;
  }, [interviews]);

  return (
    <View style={[styles.container, isMobile && styles.containerMobile]}>
      <View
        style={[styles.cardContainer, isMobile && styles.cardContainerMobile]}
      >
        <Text style={styles.number}>{completedInterviews}</Text>
        <Text style={styles.label}>Entrevistas concluídas hoje</Text>
      </View>
      <View
        style={[styles.cardContainer, isMobile && styles.cardContainerMobile]}
      >
        <Text style={styles.number}>{averageInterviewsCompletedDay}</Text>
        <Text style={styles.label}>Média de entrevistas concluídas/dia</Text>
      </View>
    </View>
  );
}

export const Card = memo(CardComponent);
