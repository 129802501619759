import { useClerk } from '@clerk/clerk-react';
import type { ReactNode } from 'react';
import { createContext, useMemo, useState } from 'react';
import * as Sentry from 'sentry-expo';

import type {
  IFormData,
  IQuestionsToSave,
  IRenderConditions,
  ISample,
  ISteps,
} from '@/interfaces/formInterfaces';
import { FormService } from '@/services/FormService';

import packageJson from '../../package.json';

interface IContext {
  children: ReactNode;
}

export const InterviewContext = createContext({
  formData: {} as IFormData | undefined,
  stepIndex: 0,
  questionIndex: 0,
  totalQuestionsStep: 1,
  currentQuestionStep: 1,
  questionsToRender: [] as ISteps[],
  answeredQuestions: [] as IQuestionsToSave[],
  saveFormData: (_formData: IFormData): void => {},
  saveAnsweredQuestions: (_data: any, _questionId: number): void => {},
  getQuestionsToRender: (): void => {},
  samplesData: [] as ISample[],
  saveSamplesData: (_samples: ISample[]): void => {},
  canAnswer: true as boolean,
  sampleToUpdate: {} as ISample,
  resetAnswers: (): void => {},
  setNextQuestion: (_skipId: number | null = null): void => {},
  getPrevQuestion: (): void => {},
  verifyFormQuestions: (_formSteps: ISteps[]): boolean => true || false,
});

export default function InterviewContextProvider({ children }: IContext) {
  const [formData, setFormData] = useState<IFormData>();
  const [stepIndex, setStepIndex] = useState(0);
  const [questionIndex, setQuestionIndex] = useState(0);
  const [questionsToRender, setQuestionsToRender] = useState<ISteps[]>([]);
  const [answeredQuestions, setAnsweredQuestions] = useState<
    IQuestionsToSave[]
  >([]);
  const [samplesData, setSamples] = useState<ISample[]>([]);
  const [sampleToUpdate, setSampleToUpdate] = useState<ISample>({} as ISample);
  const [quotasQuestions, setQuotasQuestions] = useState<number[]>([]);
  const [canAnswer, setCanAnswer] = useState<boolean>(true);
  const [returnedQuestionQty, setReturnedQuestionQty] = useState(1);

  const { session } = useClerk();

  const saveFormData = (data: IFormData) => {
    setQuotasQuestions(data.steps[0].questions.map((question) => question.id));
    setFormData(data);
  };
  const saveSamplesData = (samples: ISample[]) => {
    setSamples(samples);
  };

  const verifyQuotaSample = (
    questionsData: IQuestionsToSave[],
    lastAnswer: IQuestionsToSave,
  ) => {
    const quotaAnswers = [...questionsData, lastAnswer].filter((answer) => {
      try {
        return quotasQuestions.includes(answer.question_id);
      } catch (error) {
        const formattedAnswdQuestions = answeredQuestions.map(
          (answrdQuestion) => ({
            ...answrdQuestion,
            values: answrdQuestion.values.toString(),
          }),
        );
        const formattedAnswToRender = questionsToRender.map((step) => ({
          ...step,
          questions: step.questions.map((question) => question.id).toString(),
        }));
        Sentry.Browser.captureException(error, {
          extra: {
            questionsData,
            lastAnswer,
            quotasQuestions,
            formData,
            questionsToRender: formattedAnswToRender,
            answeredQuestions: formattedAnswdQuestions,
            stepIndex,
            questionIndex,
            clerkId: session?.user.id,
            username: session?.user.username,
            returnedQuestionQty,
            appVersion: packageJson.version,
          },
        });
        return false;
      }
    });
    const answersIds = quotaAnswers.map((answer) => answer.values).flat();
    const sampleData = samplesData.find((data) =>
      data.sampleToAnswer.every((answer) =>
        answersIds.includes(answer.answer_id.toString()),
      ),
    );
    if (sampleData && !lastAnswer.type.includes('Texto')) {
      const { sampleValue, sampleTotalAnswer } = sampleData;
      if (sampleValue > sampleTotalAnswer) {
        setCanAnswer(true);
        setSampleToUpdate(sampleData);
      } else {
        setCanAnswer(false);
      }
    } else setSampleToUpdate((prevValue) => prevValue as ISample);
  };

  const verifyNextAnsweredQuestion = (
    questionI: number,
    stepI: number,
    nextAnswerI: number,
  ) => {
    const nextAnsweredQuestion = answeredQuestions[nextAnswerI];
    const nextQuestion = formData?.steps[stepI].questions[questionI];
    if (nextAnsweredQuestion && nextQuestion) {
      if (nextQuestion.id !== nextAnsweredQuestion.question_id) {
        const filteredAnswers = answeredQuestions.filter(
          (_answer, index) => index < nextAnswerI,
        );
        const answersToDelete = answeredQuestions
          .filter((_answer, index) => index >= nextAnswerI)
          .map((answer) => answer.question_id);
        if (answersToDelete.length) {
          setReturnedQuestionQty(1);
        }
        FormService.deleteAnswers(
          answersToDelete,
          formData.interviewId as string,
        );
        setAnsweredQuestions(filteredAnswers);
        const lasAnswer = filteredAnswers[filteredAnswers.length - 1];
        verifyQuotaSample(filteredAnswers, lasAnswer);
      }
    }
  };

  const setNextQuestion = (skipId: number | null = null) => {
    setReturnedQuestionQty((prevState) => (prevState > 1 ? prevState - 1 : 1));
    if (!skipId) {
      const currStepQuestions = formData?.steps[stepIndex].questions
        .length as number;
      if (currStepQuestions - 1 > questionIndex) {
        return setQuestionIndex(questionIndex + 1);
      }
      if (stepIndex + 1 < (formData?.steps.length as number)) {
        setQuestionIndex(0);
        return setStepIndex(stepIndex + 1);
      }
    }
    return formData?.steps.forEach((step, stepSkipIndex) => {
      step.questions.forEach((question, questionSkipIndex) => {
        if (question.id === skipId) {
          setQuestionIndex(questionSkipIndex);
          setStepIndex(stepSkipIndex);
        }
      });
    });
  };

  const verifyConditions = (conditions: IRenderConditions[][]) => {
    if (conditions.length > 0) {
      const verifyCondition = conditions.some((combinedCond) => {
        return combinedCond.every((cond) =>
          answeredQuestions.some((question) =>
            question.values.includes(cond.type_answers_id.toString()),
          ),
        );
      });
      return verifyCondition;
    }
    return true;
  };

  const getQuestionsToRender = () => {
    if (formData?.steps) {
      if (stepIndex === 0 && questionIndex === 0) {
        const firstStep = formData?.steps[0] as ISteps;
        const firstQuestion = {
          ...firstStep,
          questions: [firstStep?.questions[0]],
        } as ISteps;

        return setQuestionsToRender([firstQuestion]);
      }
      const nextSteps = formData.steps[stepIndex];
      const nextQuestion = nextSteps.questions[questionIndex];
      if (nextQuestion && verifyConditions(nextQuestion.renderConditions)) {
        verifyNextAnsweredQuestion(
          questionIndex,
          stepIndex,
          answeredQuestions.length - returnedQuestionQty + 1,
        );
        const nextQuestionsToRender = [
          { ...nextSteps, questions: [nextQuestion] },
        ];
        return setQuestionsToRender(nextQuestionsToRender);
      }
      setReturnedQuestionQty((prevState) => prevState + 1);

      return setNextQuestion();
    }
    return null;
  };

  const saveAnsweredQuestions = (
    data: IQuestionsToSave,
    questionId: number,
  ) => {
    const questionWithValues =
      formData?.steps[stepIndex].questions[questionIndex];

    const dataSkipCondition = data.values
      .map((value) => {
        const valueSkipCondition = questionWithValues?.value.find(
          (valueData) => valueData.id === value,
        );
        return valueSkipCondition?.skip_to_question;
      })
      .filter((skipCondition) => typeof skipCondition === 'number');
    const filteredSavedQuestions = answeredQuestions?.filter(
      (question) => question.question_id !== questionId,
    );
    const newData = {
      ...data,
      skipTo: dataSkipCondition.length > 0 ? dataSkipCondition[0] : undefined,
    };
    const answeredQuestionIndex = answeredQuestions
      ?.map((question) => question.question_id)
      .indexOf(data.question_id);
    if (answeredQuestionIndex > -1)
      filteredSavedQuestions.splice(answeredQuestionIndex, 0, newData);
    setAnsweredQuestions(
      answeredQuestionIndex > -1
        ? filteredSavedQuestions
        : [...filteredSavedQuestions, newData],
    );
    if (quotasQuestions.includes(data.question_id)) {
      verifyQuotaSample(filteredSavedQuestions, data);
    }
    return null;
  };

  const totalQuestionsStep = useMemo(() => {
    if (formData?.steps) {
      return formData.steps[stepIndex].questions.length;
    }
    return 1;
  }, [formData, stepIndex]);

  const currentQuestionStep = useMemo(() => {
    if (formData?.steps) {
      const step = formData.steps[stepIndex];
      const currentQuestion = step.questions[questionIndex];

      const findIndexQuestion = formData.steps[stepIndex].questions.findIndex(
        (question) => question.id === currentQuestion.id,
      );

      return findIndexQuestion + 1;
    }
    return 1;
  }, [formData, stepIndex, questionIndex]);

  const resetAnswers = () => {
    setFormData({} as IFormData);
    setStepIndex(0);
    setQuestionIndex(0);
    setQuestionsToRender([]);
    setReturnedQuestionQty(1);
    setAnsweredQuestions([]);
    setSamples([]);
    setSampleToUpdate({} as ISample);
    setQuotasQuestions([]);
    setCanAnswer(true);
  };

  const getPrevQuestion = () => {
    const isRenderedQuestion = questionsToRender.some((step) =>
      step.questions.some(
        (question) =>
          question.id ===
          answeredQuestions[answeredQuestions.length - returnedQuestionQty]
            ?.question_id,
      ),
    );
    const lastAnsweredQuestion = isRenderedQuestion
      ? answeredQuestions[answeredQuestions.length - returnedQuestionQty - 1]
      : answeredQuestions[answeredQuestions.length - returnedQuestionQty];
    if (lastAnsweredQuestion && formData) {
      formData.steps.forEach((step) => {
        const questionWithIndex = step.questions.map((question, index) => ({
          id: question.id,
          index,
        }));
        const lastQuestionIndex = questionWithIndex.find(
          (question) => question.id === lastAnsweredQuestion.question_id,
        );
        if (lastQuestionIndex) {
          setQuestionIndex(lastQuestionIndex.index);
          setStepIndex(step.order - 1);
        }
      });
      setReturnedQuestionQty((prevState) => prevState + 1);
    }
  };

  const verifyFormQuestions = (formSteps: ISteps[]) => {
    const checked = !formSteps.some((step) => {
      const questionsToAnswer = step.questions.length;
      return questionsToAnswer !== step.totalQuestions;
    });

    if (!checked) {
      resetAnswers();
    }

    return checked;
  };
  // eslint-disable-next-line react/jsx-no-constructed-context-values
  const value = {
    formData,
    stepIndex,
    questionIndex,
    questionsToRender,
    answeredQuestions,
    samplesData,
    canAnswer,
    sampleToUpdate,
    saveSamplesData,
    getQuestionsToRender,
    saveFormData,
    saveAnsweredQuestions,
    resetAnswers,
    setNextQuestion,
    totalQuestionsStep,
    currentQuestionStep,
    getPrevQuestion,
    verifyFormQuestions,
  };

  return (
    // eslint-disable-next-line react/react-in-jsx-scope
    <InterviewContext.Provider value={value}>
      {children}
    </InterviewContext.Provider>
  );
}
