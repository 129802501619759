/* fonts */
export const FontFamily = {
  regular: 'Inter_400Regular',
  medium: 'Inter_500Medium',
  semiBold: 'Inter_600SemiBold',
  bold: 'Inter_700Bold',
  robotoRegular: 'Roboto_400Regular',
  robotoMedium: 'Roboto_500Medium',
};
/* font sizes */
export const FontSize = {
  xs: 12,
  sm: 14,
  base: 16,
  lg: 18,
  xl: 20,
  '2_125xl': 34,
};
/* spacing */
export const Spacing = {
  xs2: 6,
  xs: 12,
  sm: 14,
  medium: 16,
  lg: 18,
  xl: 20,
};
/* Colors */
export const Color = {
  whiteSmoke_100: '#f7f7f7',
  reddishOrange: '#fc4c02',
  dimGray: '#666',
  darkSlateGray_100: '#333',
  primary: {
    400: '#153F5C',
    300: '#2776A7',
    opacity_12_300: 'rgba(39, 118, 167, 0.12)',
    200: '#3899DC',
    100: '#7AB8E1',
  },
  gray: {
    black: '#000000',
    opacity_08_black: 'rgba(0, 0, 0, 0.08)',
    400: '#242524',
    300: '#3C3C3C',
    200: '#838383',
    100: '#D9D9D9',
    50: '#F3F3F3',
    white: '#FFFFFF',
  },
  secondary: {
    brightPurple: '#C73DB9',
    pink: '#FF0066',
    red: '#F73131',
    'red-0_12': '#f731311f',
    orange: ' #FF6728',
    yellow: '#F1B01B',
    green: '#29D975',
    turquoise: '#1BE7C0',
    blue: '#168BF2',
    blueLight: '#52B5F2',
    royalPurple: '#7B7CDA',
    deepPurple: '#5555C6',
  },
};
/* Paddings */
export const Padding = {
  p_xs: 12,
  p_5xs: 8,
  p_base: 16,
  p_xl: 20,
  p_5xl: 24,
  p_sm: 14,
  p_81xl: 100,
};
/* border radiuses */
export const Border = {
  xs: 4,
  lg: 8,
};

export const Stroke = {
  1: 1,
  1.2: 1.2,
  1.5: 1.5,
  2: 2,
  3: 3,
  4: 4,
};
