import { StyleSheet } from 'react-native';

import {
  Color,
  FontFamily,
  FontSize,
  Padding,
  Stroke,
} from '@/styles/GlobalStyles';

export const styles = StyleSheet.create({
  rows: {
    flexDirection: 'row',
    flex: 1,
    width: '100%',
  },
  row: {
    paddingVertical: 10,
    borderBottomWidth: 1,
    borderColor: Color.gray[100],
    borderStyle: 'solid',
    borderRightWidth: 1,
    paddingHorizontal: Padding.p_5xs,
    height: 40,
    flexDirection: 'row',
    backgroundColor: Color.gray.white,
    alignSelf: 'stretch',
    alignItems: 'center',
    flex: 1,
  },
  rowId: {
    width: 70,
  },
  status: {
    gap: 8,
  },
  icon: {
    height: 24,
    width: 24,
    strokeWidth: Stroke[2],
    color: Color.gray.white,
  },
  text: {
    color: Color.gray[400],
    fontFamily: FontFamily.regular,
    flex: 1,
    lineHeight: 20,
    textAlign: 'left',
    fontSize: FontSize.sm,
  },
});
